import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { BaseUrlContext } from './baseprovider/BaseUrlProvider';
import { Icon } from 'base';
import { FaChevronLeft } from 'react-icons/fa';
import { Trans } from 'base';

function BackButton() {
    const [hasBackButton , setHasBackButton ] = useState(false);
    const baseUrl = useContext(BaseUrlContext)
    const location = useLocation()
    useEffect(() => {
        if (location.pathname.includes("support")) {
            setHasBackButton(true)
        } else {
            setHasBackButton(false)
        }
    })
    
  return (
    <NavLink className={`is-absolute ${!hasBackButton ? "is-hidden" : ""}`} to={baseUrl} style={{ top: "0.2rem", }}>
        <div className="is-flex is-align-items-center">
            <Icon icon={FaChevronLeft} className="mr-1" />
            <Trans id={"default.back"} />
        </div>
    </NavLink>
  )
}

export default BackButton