import { useField } from "formik";
import React, { useContext, useEffect } from "react";
import { IntlContext } from "../../core/IntlContext";
import { Trans } from "../Trans";
import { FormTextInput } from "./Input";

export function FormZipAndCity({ zipName = "zip", cityName = "city" }) {
    const intl = useContext(IntlContext);
    const [zipField] = useField(zipName);
    const [field, meta, helpers] = useField(cityName);

    useEffect(() => {
        if (intl.getCountry().toLowerCase() === "at" && zipField.value >= 1000) {
            getCityNameForZipAT(zipField.value)
                .then((result) => {
                    helpers.setValue(result);
                })
                .catch((error) => {
                    helpers.setValue("");
                });
        }
    }, [zipField.value]);

    function getCityNameForZipAT(zip) {
        return fetch("https://data.rtr.at/api/v1/tables/plz.json?plz=" + zip)
            .then((response) => {
                if (response.ok) {
                    return response.json().then((data) => {
                        if (data.data.length > 0) {
                            return Promise.resolve(data.data[0].ort);
                        } else {
                            return Promise.reject();
                        }
                    });
                } else {
                    return Promise.reject();
                }
            })
            .catch((error) => {
                return Promise.reject();
            });
    }

    return (
        <div className="columns">
            <div className="column is-4">
                <FormTextInput
                    name={zipName}
                    label={<Trans id="user.props.zip" />}
                    placeholder="1190"
                    type="number"
                    autoComplete={"postal-code"}
                />
            </div>
            <div className="column">
                <FormTextInput name={cityName} label={<Trans id="user.props.city" />} placeholder="Wien" autoComplete={"address-level2"} />
            </div>
        </div>
    );
}
