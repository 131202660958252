import { config } from "./config";
import { showTooManyRequestInfo } from "./helper";
import { LocalStorage } from "./storage";
import queryString from "query-string";

export const apiParameterDefaults = {
    limit: 10,
};

export const API = {
    // returns a promise with the result on success/failure
    // pass an empty token, if your request does not need a token
    // datatype: 'json' | 'form'
    request(url, method, token, data = "", version = "v1", dataType = "") {
        if (API.hasTokenExpired(token)) {
            // NOTE: we don't want to specify refresh_tokens with every request
            // but this hardcoding makes it hard to modify this behavior
            return _requestTokenRefresh(API.getRefreshToken())
                .catch((error) => {
                    console.log("could not get refresh token");
                    return Promise.reject(error);
                })
                .then((newTokens) => {
                    API.setToken(newTokens.token);
                    API.setRefreshToken(newTokens.refresh_token);
                    return _request(url, method, newTokens.token, data, version, dataType);
                });
        } else {
            return _request(url, method, token, data, version, dataType);
        }
    },

    getRequest(url, token, params = {}, version = "v1", dataType = "") {
        url = url + "?" + queryString.stringify(params);
        return this.request(url, "GET", token, "", version, dataType);
    },

    refreshTokens() {
        return _requestTokenRefresh(API.getRefreshToken())
            .then((newTokens) => {
                API.setToken(newTokens.token);
                API.setRefreshToken(newTokens.refresh_token);
                return Promise.resolve();
            })
            .catch((error) => {
                API.clearTokens();
                return Promise.reject(error);
            });
    },

    hasTokenExpired(token) {
        const jwt = API.parseJwt(token);
        return timestamp() >= jwt.exp;
    },

    parseJwt(token) {
        if (!token) return {};
        var base64Url = token.split(".")[1]; // TODO: ?? verify validity, check signature [2]
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );
        return JSON.parse(jsonPayload);
    },

    // call once on startup
    initTokens() {
        const token = LocalStorage.get("token");
        const refreshToken = LocalStorage.get("refresh_token");
        const now = timestamp();
        if (token && now > JSON.parse(token).expires) {
            LocalStorage.remove("token");
        }
        if (refreshToken && now > JSON.parse(refreshToken).expires) {
            LocalStorage.remove("refresh_token");
        }
    },

    getToken() {
        const token = LocalStorage.get("token");
        return token ? JSON.parse(token).value : "";
    },

    getRefreshToken() {
        const token = LocalStorage.get("refresh_token");
        return token ? JSON.parse(token).value : "";
    },

    setToken(token) {
        LocalStorage.set(
            "token",
            JSON.stringify({
                value: token,
                expires: timestamp() + daysToSeconds(1),
            })
        );
    },

    setRefreshToken(token) {
        LocalStorage.set(
            "refresh_token",
            JSON.stringify({
                value: token,
                expires: timestamp() + daysToSeconds(30),
            })
        );
    },

    clearTokens() {
        LocalStorage.remove("token");
        LocalStorage.remove("refresh_token");
    },

    getEndpoint() {
        return config("api");
    },
};

function daysToSeconds(days) {
    return days * (60 * 60 * 24);
}

// unix timestamp in seconds
function timestamp() {
    return Math.floor(Date.now() / 1000);
}

// internal
function _request(url, method, token, data, version, dataType = "") {
    let request = new XMLHttpRequest();
    const apiUrl = buildUrl(url, version);
    const rand = Math.floor(Math.random() * 100);

    console.log("[" + rand + "] API Request:", apiUrl);

    return new Promise(function (resolve, reject) {
        request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
                resolve(request.response);
            } else if (request.status === 429) {
                showTooManyRequestInfo();
                reject({
                    response: request.response,
                    status: request.status,
                });
            } else {
                //console.log('API Request rejected');
                reject({
                    response: request.response,
                    status: request.status,
                });
            }
        };

        request.open(method, apiUrl);
        request.responseType = "json";
        if (token) {
            request.setRequestHeader("Authorization", "Bearer " + token);
        }

        if (data) {
            if (dataType === "json" || dataType === "") {
                request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                request.send(data);
            } else if (dataType === "form") {
                request.send(data);
            }
        } else {
            request.send();
        }
    })
        .then(function (response) {
            console.log("[" + rand + "] API Response:", response);
            return Promise.resolve(response);
        })
        .catch(function ({ response, status }) {
            let errorObj = {
                key: (response && response.key) || "error.unknown",
                message: (response && response.message) || "Error.",
                errors: response && response.errors,
                status: status,
                response: response,
            };
            console.log("[" + rand + "] API Rrequest Rejected:", errorObj);
            return Promise.reject(errorObj);
        });
}

function _requestTokenRefresh(refreshToken) {
    return _request(
        "token/refresh",
        "POST",
        "",
        JSON.stringify({
            refresh_token: refreshToken,
        }),
        ""
    );
}

function buildUrl(url, version) {
    let result = API.getEndpoint() + "/";
    if (version) {
        result += version + "/";
    }
    return result + url;
}
