import React, { useContext, useState } from "react";
import { CountrySelector, CountrySelectorButton } from "./CountrySelector";
import { LanguageSelector } from "./LanguageSelector";
import { Trans, useTrans } from "./Trans";
import { Link } from "react-router-dom";
import { BaseUrlContext } from "./baseprovider/BaseUrlProvider";
import BackButton from "./BackButton";

// Use Countrypicker prop to enable countrypicker in footer (boolean)

export function DefaultFooter({ availableCountries = [], countryPicker = false }) {
    const [isCountrySelectorVisible, setCountrySelectorVisible] = useState(false);
    const baseUrl = useContext(BaseUrlContext);
    const currentYear = new Date().getFullYear();
    return (
        <>
            <footer className="footer">
                <div className="container is-size-7 is-measure-wide">
                    <div className="level is-mobile mb-3">
                        <BackButton />
                        <div className="level-item has-text-centered is-flex is-flex-direction-column">
                            <LanguageSelector />
                            {countryPicker ? (
                                <CountrySelectorButton
                                    availableCountries={["at", "de", "ch"]}
                                    onClick={() => setCountrySelectorVisible(true)}
                                    className={"mt-3"}
                                />
                            ) : null}
                        </div>
                    </div>
                    <div className="has-text-grey-dark has-text-centered" style={{ margin: "2% 0" }}>
                        <a type="button" data-cc="c-settings" onClick={openCookieSettings}>
                            {useTrans("footer.cookiepolicy")}
                        </a>
                        {" / "}
                        <a href="https://www.iamstudent.at/impressum" target="_blank">
                            {useTrans("imprint")}
                        </a>
                        {" / "}
                        <Link to={baseUrl + "support"}>{useTrans("footer.contact")}</Link>
                    </div>
                    <div className="has-text-grey-dark has-text-centered">
                        <Trans id="footer.copyright" containsHTML values={{ year: currentYear }} />
                    </div>
                </div>
            </footer>
            <CountrySelector visible={isCountrySelectorVisible} onClose={closeCountrySelector} availableCountries={availableCountries} />
        </>
    );

    function closeCountrySelector() {
        setCountrySelectorVisible(false);
    }
    function openCookieSettings() {
        console.log("press");
        if (window.initCookieConsent()) {
            var cookieconsent = window.initCookieConsent();
            cookieconsent.showSettings();
        }
    }
}
