import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { NavLink } from "../../../components/Link";
import { Trans } from "../../../components/Trans";

export function MyOrganisationIsNotThere({ baseUrl, email, isAction = false }) {
    const location = useLocation();
    const history = useNavigate();

    let url = baseUrl + "/organisation/request?email=" + encodeURIComponent(email);
    if (location.search) {
        url += "&" + location.search.slice(1);
    }

    if (isAction) {
        return (
            <div className="notification is-info mb-4">
                <div className="is-size-6">
                    <Trans id="register.form.error.organisation.invalid" />
                </div>
                <Button
                    onClick={() => {
                        history(url);
                    }}
                    className="mt-4 is-small is-info is-light has-text-white is-multiline"
                >
                    <Trans id="register.form.error.organisation.request" />
                </Button>
            </div>
        );
    } else {
        return (
            <div className="is-size-7 has-text-centered mt-6">
                <Link to={url} target="_blank" rel="noopener noreferrer" className="has-text-grey-darker">
                    <Trans id="register.organisation.missing" />
                </Link>
            </div>
        );
    }
}
