import { API, clamp, hexToRgb, IntlContext, Link, LocalStorage, PageAnimation, UserVerificationState, useWindowWidth } from "base";
import React, { useContext, useEffect, useState } from "react";
import { useMatch, useOutletContext } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import { UserContext } from "../../base";
import VerificationBar from "./VerificationBar";
import VoucherView from "./VoucherView";

function DefaultView({ isLoggedIn, error, loginPath = "login", registerPath }) {
    //set AppInfo
    const [appInfo, setAppInfo] = useOutletContext();
    //Refactor AppInfo to variable
    const [activeVoucher, setActiveVoucher] = useState(0);
    const vouchers = appInfo ? appInfo.vouchers : null;
    const voucherIndex = vouchers ? clamp(activeVoucher, 0, Math.max(vouchers.length - 1, 0)) : 0;
    const voucher = vouchers && vouchers.length ? vouchers[voucherIndex] : null;
    const company = appInfo ? appInfo.company : null;
    const isLocalStorageAvailable = LocalStorage.isAvailable();
    const [userInfo, setUserInfo] = useState(null);
    //State for google analytics Tracking
    const [voucherSeen, setVoucherSeen] = useState([]);
    //init User
    const User = useContext(UserContext);
    //init Company and Country
    const match = useMatch("/:locale/:company/*");
    const imageRef = React.useRef(null);
    const theme = useContext(ThemeContext);
    const backgroundColor = theme.color.background;
    //Variable for checking if verified
    const isVerified = User.verificationStatus === UserVerificationState.VERIFIED;
    // wait a bit with logging, until site has loaded (performance)
    useEffect(() => {
        if (isVerified && voucher && isLoggedIn) {
            setTimeout(voucherlog, 500);
        }
        if (!isVerified && isLoggedIn) {
            API.request("me", "GET", API.getToken())
                .then((response) => {
                    setUserInfo(response);
                })
                .catch((error) => {
                    console.log("couldn't get data: ", error);
                });
        }
    }, [isVerified]);
    //set inVerify false for redirect logic in register
    useEffect(() => {
        if (User) {
            User.setInVerify(false);
        }
    }, []);

    useEffect(() => {
        if (isVerified && voucher && isLoggedIn) {
            setTimeout(voucherlog, 500);
        }
    }, [activeVoucher]);

    function logUser() {
        const logData = {
            app: voucher.app.name,
            country: voucher.country.tld,
            event: "user_visits_voucher",
            voucher: {
                id: voucher.id,
                permalink: voucher.permalink,
            },
            company: {
                id: voucher.company.id,
                permalink: voucher.company.permalink,
            },
        };

        API.request("log/event", "POST", "", JSON.stringify(logData));
    }
    function voucherlog() {
        console.log(voucherSeen + voucher.id);
        if (voucherSeen.length == 0 || !voucherSeen.includes(voucher.id)) {
            logUser();
            setVoucherSeen([...voucherSeen, voucher.id]);
        }
    }

    return (
        <>
            <VerificationBar company={match.params.company} userInfo={userInfo} />
            <div className="columns is-gapless is-marginless" style={{ backgroundColor: backgroundColor }}>
                <div className="column is-half" style={{ lineHeight: "0" }} ref={imageRef}>
                    <GumletImage url={match.params.company + ".jpg"} width={imageRef.current ? imageRef.current.offsetWidth : 0} />
                </div>
                <div className="column is-half has-text-centered is-relative is-clipped">
                    <>
                        <VoucherTabs vouchers={vouchers} activeIndex={voucherIndex} setVoucher={(index) => setActiveVoucher(index)} />
                        <PageAnimation pageIndex={voucherIndex}>
                            <VoucherView
                                appInfo={appInfo}
                                appInfoError={error}
                                hasLoaded={appInfo != null}
                                isLoggedIn={isLoggedIn}
                                isVerified={isVerified}
                                userStatusError={User.userStatusError}
                                voucher={voucher}
                                company={company}
                                loginPath={loginPath}
                                registerPath={registerPath}
                                isLocalStorageAvailable={isLocalStorageAvailable}
                            />
                        </PageAnimation>
                    </>
                </div>
            </div>
        </>
    );
}
function VoucherTabs({ vouchers, activeIndex, setVoucher }) {
    const theme = useContext(ThemeContext);
    if (!vouchers || vouchers.length < 2) {
        return null;
    }

    const activeColor = theme && theme.color.primary ? theme.color.primary : "#d41f39";

    const activeTabStyle = {
        backgroundColor: "rgba(" + hexToRgb(activeColor) + ", 0.12)",
        borderBottom: "2px solid " + activeColor,
        marginBottom: -2,
        color: activeColor,
    };
    console.log("iamchanging");
    return (
        <div className="tabs is-marginless is-centered" style={{ border: 0 }}>
            <ul style={{ borderBottomWidth: 2 }}>
                {vouchers.map((_, index) => {
                    const isActive = index === activeIndex;
                    return (
                        <li key={index} className="is-padding" style={isActive ? activeTabStyle : null}>
                            <Link onClick={() => setVoucher(index)} className="color-unset">
                                <span
                                    className="has-text-weight-bold"
                                    style={{
                                        opacity: 0.8 + isActive * 0.2,
                                    }}
                                >
                                    {vouchers[index].savingDescription}
                                </span>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

function GumletImage({ url, width }) {
    const device = useWindowWidth() > 768 ? "desktop" : "mobile";
    const Intl = React.useContext(IntlContext);
    const country = Intl.getCountry().toLowerCase();
    const imageUrl = getGumletImageUrl(url, device, "connect", country, width);
    return <GumletComponent imageUrl={imageUrl} device={device} />;
}

const GumletComponent = React.memo(({ imageUrl, device }) => {
    if (device === "mobile") {
        return <img alt={""} className={"is-full-width"} src={imageUrl} />;
    } else {
        return <StyledGumletImage url={imageUrl} isMobile={device === "mobile"} />;
    }
});

function getGumletImageUrl(img, device, category, country, width = 0) {
    return `https://img.iamstudent.com/${category}/${country}/${device}/${img}?crop=smart&w=${width}`;
}

const StyledGumletImage = styled.div`
    background-image: url(${(props) => props.url});
    background-size: cover;
    background-position: top center;
    height: 100%;
`;

export default DefaultView;
