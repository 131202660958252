import { DefaultFooter } from "base";
import React from "react";
import { HeroTitle } from "../../base/components/HeroTitle";

function IndexRoute(props) {
    return (
        <div>
            <HeroTitle title="iamstudent verify" />
            <div className="container">
                <div className="section has-text-centered">
                    <h2 className="subtitle">Hi there!</h2>
                    <p>
                        This page is not supposed to be opened directly.
                        <br />
                        If you are looking for more information about student verification & discounts please go to{" "}
                        <a target={"_blank"} rel="noreferrer" href={"https://b2b.iamstudent.com?utm_source=verify-index&utm_medium=web"}>
                            b2b.iamstudent.com
                        </a>
                    </p>
                </div>
            </div>
            <DefaultFooter />
        </div>
    );
}

export default IndexRoute;
