import React, { useState } from "react";
import InkognitoVideo from "../../assets/inkognito.mp4";
import InkognitoPoster from "../../assets/inkognito_poster.jpg";
import { IntlContext, isBrowser } from "../../core";
import { LocalStorage } from "../../core/storage";
import { Link } from "../Link";
import { Modal } from "../Modal";
import { NotificationBar } from "../NotificationBar";
import { Trans } from "../Trans";

export function AppRequirementsBar({ redirectUrl }) {
    const Intl = React.useContext(IntlContext);
    const lang = Intl.getLanguage();
    const [modalIsVisible, setModalVisible] = useState(false);

    if (LocalStorage.isAvailable()) {
        return null;
    }

    if (modalIsVisible) {
        return (
            <Modal isVisible={modalIsVisible} onClose={close} maxWidth={"600px"}>
                <h1 className="title is-4">
                    <Trans id="cookies.modal.title" />
                </h1>
                <h2 className="subtitle is-6">
                    <Trans id="cookies.modal.subtitle" />
                </h2>

                <h2 className="subtitle is-6 has-text-weight-bold is-marginless">
                    <Trans id="cookies.modal.inkognito.title" />
                </h2>
                <p>
                    <Trans id="cookies.modal.inkognito.description" />
                </p>
                <video src={InkognitoVideo} controls poster={InkognitoPoster} className="mb-5" autoPlay>
                    Sorry, your browser does not support embedded videos.
                </video>

                <h2 className="subtitle is-6 is is-marginless has-text-weight-bold">
                    <Trans id="cookies.modal.disabled.title" />
                </h2>
                <p>
                    <Trans id="cookies.modal.disabled.description" />
                </p>
                <ul className="list">
                    <li className="list-item">
                        <Link to={"https://enablecookies.info/" + lang + "/#en-chrome"}>Chrome</Link>
                    </li>
                    <li className="list-item">
                        <Link to={"https://enablecookies.info/" + lang + "/#en-firefox"}>Firefox</Link>
                    </li>
                    <li className="list-item">
                        <Link to={"https://enablecookies.info/" + lang + "/#en-safari"}>Safari</Link>
                    </li>
                    <li className="list-item">
                        <Link to={"https://enablecookies.info/" + lang + "/#en-edge"}>Edge</Link>
                    </li>
                    <li className="list-item">
                        <Link to={"https://enablecookies.info/" + lang + "/#en-ie"}>Internet Explorer</Link>
                    </li>
                </ul>
            </Modal>
        );
    }

    return (
        <NotificationBar isOverlay={false}>
            <div className="is-flex-tablet is-flex-center is-vcentered">
                <p>
                    <Trans id="cookies.not_enabled" />
                    <br />
                    <Link onClick={() => setModalVisible(true)}>
                        <Trans id="cookies.how_to_enable" />
                    </Link>
                    <br />
                    <br />
                    <Trans id="cookies.adblocker.question" />
                    <br />
                    <Link to={redirectUrl || (isBrowser() ? window.location.href : "")} openNewTab>
                        <Trans id="cookies.adblocker.answer" />
                    </Link>
                </p>
            </div>
        </NotificationBar>
    );

    function close() {
        setModalVisible(false);
    }
}
