import React, { useEffect, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import arrow from "../assets/arrow.png";

export function Dropzone({ onDrop, hint = "", children }) {
    const ref = useRef(null);
    const [isHovering, setHovering] = useState(false);
    const [isDropped, setDropped] = useState(false);

    useEffect(() => {
        function onAnimationEnd() {
            setDropped(false);
        }

        if (ref && ref.current) {
            ref.current.addEventListener("animationend", onAnimationEnd);
            return () => {
                if (ref && ref.current) {
                    ref.current.removeEventListener("animationend", onAnimationEnd);
                }
            };
        }
    }, []);

    return (
        <>
            {hint && (
                <Hint>
                    <span className="has-text-grey-dark help is-marginless mr-1">{hint}</span>
                    <img src={arrow} alt="Arrow" data-gumlet="false" />
                </Hint>
            )}
            <Zone ref={ref} onDragOver={handle} onDrop={handle} onDragLeave={handle} isDropped={isDropped} isHovering={isHovering}>
                {children}
            </Zone>
        </>
    );

    function handle(e) {
        e.preventDefault();
        if (e.type === "dragover") {
            setHovering(true);
        } else if (e.type === "dragleave") {
            setHovering(false);
        } else if (e.type === "drop") {
            setHovering(false);
            const items = e.dataTransfer.items;
            let file;
            if (items && items.length === 1 && items[0].kind === "file") {
                file = items[0].getAsFile();
            } else {
                const files = e.dataTransfer.files;
                file = files.length === 1 && files[0];
            }
            if (file && onDrop && typeof onDrop === "function") {
                onDrop(file);
                setDropped(true);
            }
        }
    }
}

const dropBounce = keyframes`
    0% { transform: scale(1); }
    50% { transform: scaleY(0.9); }
    100% { transform: scale(1); }
`;

const Hint = styled.div.attrs((props) => ({
    className: "is-hidden-mobile is-flex is-align-items-center",
}))`
    padding: 4px 1.25rem;
`;

const Zone = styled.div.attrs((props) => ({
    className: "box",
}))`
    border: ${(props) => (props.isHovering ? "1px dashed black" : "1px dashed #00000033")};
    transition: all 0.15s ease-out;
    transform-origin: center bottom;
    animation: ${(props) =>
        props.isDropped &&
        css`
            ${dropBounce} 0.4s ease-out
        `};
    ${(props) => props.isHovering && "background: #ededed"};
`;
