import React from "react";
import * as Yup from "yup";
import { Trans } from "../../../components/Trans";
import { MyOrganisationIsNotThere } from "./MyOrganisationIsNotThere";

export function validatePage1(values, organisation, texts) {
    let errors = {};
    if (!organisation || !organisation.studyPrograms || organisation.studyPrograms.length === 0) {
        return errors;
    }

    // Not used anymore because it is possible to enter invalid study program text which is ignored
    // if (values.organisation_study_program) {
    //
    //
    //     const inputValue = values.organisation_study_program;
    //
    //     console.log(inputValue);
    //     const result = organisation.studyPrograms.filter((program) => {
    //         return inputValue === program.title;
    //     });
    //     if (result.length === 0) {
    //         errors.organisation_study_program = texts.studyProgramUnknownError;
    //     }
    // }

    return errors;
}

export function validatePage2(values, organisation, uniMailIsWriteable, texts) {
    let errors = {};
    if (organisation && organisation.tld) {
        if (uniMailIsWriteable && !values.email_other) {
            errors.email_other = texts.emailOtherError;
        }
    }
    return errors;
}
export function validatePage3(values, studyId, texts) {
    let errors = {};
    if (!studyId) {
        errors.studyId = "no study id";
    }
    return errors;
}
export function schemaPage1(values, organisation, email, baseUrl) {
    return Yup.object().shape({
        organisation: Yup.string()
            .required(<Trans id="register.form.error.organisation" />)
            .test("organisation", <MyOrganisationIsNotThere isAction baseUrl={baseUrl} email={email} />, (value) => {
                return organisation !== null;
            }),
        start_of_studies: Yup.number().required(<Trans id="register.form.error.start_of_studies" />),
    });
}

export function schemaPage2(
    values,
    organisation,
    setMatriculationNumberIsVerifying,
    setMatriculationNumberIsValid,
    validateMatriculationNr,
    userInfo
) {
    console.log("schemaPage2", organisation, values, userInfo);
    return Yup.object().shape({
        matriculation_number: Yup.string().test(
            "matriculation_number",
            <Trans id="register.form.error.matriculation_number.invalid" />,
            (values) => {
                if (organisation && organisation.disableMatriculationNumber === true) {
                    return Promise.resolve(true);
                } else if (!values || values.length < 4 || !/^[A-Za-z0-9\-\/]*$/.test(values)) {
                    return Promise.resolve(false);
                } else {
                    if (userInfo?.organisation?.id === organisation?.id && userInfo?.matriculationNumber === values) {
                        return Promise.resolve(true);
                    }
                    return checkMatriculationNr(values);
                }
            }
        ),
        email_other: Yup.string()
            .email(<Trans id="register.form.error.email_other" />)
            .test("email_other", <Trans id="error.email_other.required" />, (value) => {
                return value !== null;
            }),
    });

    function checkMatriculationNr(value) {
        setMatriculationNumberIsVerifying(true);
        setMatriculationNumberIsValid(false);
        return validateMatriculationNr(value, organisation["id"])
            .then((response) => {
                setMatriculationNumberIsValid(true);
                return Promise.resolve(true);
            })
            .catch((error) => {
                return Promise.resolve(false);
            })
            .finally(() => {
                setMatriculationNumberIsVerifying(false);
            });
    }
}

export function schemaPage3(values, texts) {
    return Yup.object().shape({
        private_email: Yup.string().email(texts.emailInvalid),
    });
}
export function schemaPage4(values, courseId, texts) {
    return Yup.object().shape({
        organisation_study_program: Yup.string().required("required"),
    });
}

export function emailSchema() {
    const emailTypos = [
        "gmail.con",
        "gnail.com",
        "gmai.com",
        "gamil.com",
        "gmial.com",
        "gmx.dd",
        "htmail.com",
        "hotmil.com",
        "hotmal.com",
        "web.ee",
        "wen.de",
        "gmx.ocom",
    ];
    return Yup.object().shape({
        email: Yup.string()
            .email(<Trans id="error.email.invalid" />)
            .required(<Trans id="error.email.required" />)
            .test("email_typo", <Trans id="error.email.invalid" />, (value) => {
                if (value) {
                    let domain = value.split("@").pop();
                    return !emailTypos.includes(domain);
                }
            }),
    });
}

export function loginSchema() {
    return Yup.object().shape({
        email: Yup.string()
            .email(<Trans id="error.email.invalid" />)
            .required(<Trans id="error.email.required" />),
        password: Yup.string().required(<Trans id="error.password.required" />),
    });
}
