import React from "react";
import styled from "styled-components";

export function LoadingIndicatorCentered({ fillScreen = true, size = "4rem" }) {
    return (
        <Styled fillScreen={fillScreen}>
            <div className="loader" style={{ width: size, height: size }}></div>
        </Styled>
    );
}

const Styled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.fillScreen ? "100vw" : "100%")};
    height: ${(props) => (props.fillScreen ? "100vh" : "100%")};
`;
