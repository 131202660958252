import React from "react";
import { Trans } from "../../../components/Trans";

export function MyStudyProgramIsNotThere() {
    return (
        <div className="notification is-info mb-4">
            <Trans id="register.organisation_study_program.missing" containsHTML />
        </div>
    );
}
