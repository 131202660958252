import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import FocusTrap from "focus-trap-react";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled, { keyframes } from "styled-components";
import { inIframe, isBrowser } from "../core/helper";
import { redirect, useNavigate } from "react-router-dom";

// position = 'top' | 'bottom' (default: center)
export function Modal({ onClose, isVisible, position = "center", maxWidth = "100%", children, stylesContent = {}, className }) {
    const modalRef = useRef(null);
    const closeButtonRef = useRef(null);
    const [focusTrapped, setFocusTrapped] = useState(false);
    const max = typeof maxWidth === "number" ? maxWidth + "px" : maxWidth;

    useEffect(() => {
        setFocusTrapped(isVisible);
        if (isVisible) {
            const element = document.body;
            disableBodyScroll(element);
            if (onClose && closeButtonRef) {
                closeButtonRef.current.focus();
            }

            // when inside iframe ensure that the modal is visible; scroll to top of iframe via iamstudent.verify.js
            if (inIframe()) {
                const data = {
                    modalScroll: true,
                    id: "iam-verify-iframe",
                };
                window.parent.postMessage(data, "*");
            }

            return () => {
                enableBodyScroll(element);
                setFocusTrapped(false);
            };
        }
    }, [isVisible]);

    const modal = (
        <FocusTrap active={focusTrapped} focusTrapOptions={{ clickOutsideDeactivates: true }}>
            <Main isActive={isVisible} position={position} ariaModal="true" tabIndex="-1" role="dialog" onKeyDown={handleKey}>
                <ModalBackground onClick={hide} isActive={isVisible} tabIndex={0} />
                <ModalWrapper maxWidth={max} position={position}>
                    <ModalContent
                        ref={modalRef}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={stylesContent}
                        className={className}
                    >
                        {children}
                    </ModalContent>
                </ModalWrapper>
                <ModalClose ariaLabel="close" onClick={hide} ref={closeButtonRef}></ModalClose>
            </Main>
        </FocusTrap>
    );

    function hide() {
        if (onClose) onClose();
    }

    function handleKey(event) {
        if (event.keyCode === 27) {
            hide();
        }
    }

    if (isBrowser()) {
        try {
            return createPortal(modal, document.getElementById("App"));
        } catch {
            return createPortal(modal, document.getElementById("__next"));
        }
    } else {
        return null;
    }
}

export function ModalHeaderNotification({ children }) {
    return <StyledHeaderNotification>{children}</StyledHeaderNotification>;
}

export function ModalFooterInfo({ children }) {
    return <StyledFooterInfo>{children}</StyledFooterInfo>;
}

const modalFade = keyframes`
    from {
      transform: translateY(-20%);
      opacity: 0.2;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const modalFadeUp = keyframes`
    from {
      transform: translateY(20%);
      opacity: 0.2;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const Main = styled.aside.attrs((props) => ({
    className: "modal" + (props.isActive ? " is-active" : ""),
}))`
    color: ${(props) => props.theme.color.text};
    justify-content: ${(props) => (props.position === "bottom" ? "flex-end" : props.position === "top" ? "flex-start" : "")};

    ${(props) => props.position === "bottom" && "padding-bottom: 40px;"};
    ${(props) => props.position === "top" && "padding-top: 50px;"};

    @media (max-width: 640px) {
        justify-content: flex-start;
        padding-top: 50px;
    }
`;

const ModalBackground = styled.div.attrs((props) => ({
    className: "modal-background",
}))`
    transition: all 0.3s ease-in-out;
`;

const ModalWrapper = styled.div.attrs((props) => ({}))`
    position: relative;
    animation: ${(props) => (props.position === "bottom" ? modalFadeUp : modalFade)} 0.3s ease-out;
    width: 100%;
    max-width: ${(props) => props.maxWidth};
`;

const ModalContent = styled.div.attrs((props) => ({
    className: "modal-content",
}))`
    max-height: calc(100vh - 50px);
    background-color: ${(props) => props.theme.color.background};
    padding: 1.5rem;
    width: auto;
    margin: 10px;
`;

const ModalClose = styled.button.attrs((props) => ({
    className: "modal-close is-large",
}))``;

const StyledHeaderNotification = styled.div.attrs((props) => ({
    className: "notification is-info",
}))`
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-top: -1.5rem;
`;

const StyledFooterInfo = styled.div`
    background-color: rgb(243, 243, 243);
    margin: 1.5rem -1.5rem -1.5rem -1.5rem;
    padding: 1.5rem;
`;
