import { API } from "./api";
import { isBrowser, uniqueArray } from "./helper";

export const ImpressionLog = {
    data: {},

    init() {
        if (isBrowser()) {
            if (document.visibilityState) {
                document.addEventListener("visibilitychange", () => {
                    if (document.visibilityState === "hidden") {
                        this.send();
                    }
                });
            } else {
                window.addEventListener("unload", () => {
                    this.send();
                });
            }
        }
    },

    add(source, companyId, type, id) {
        if (typeof this.data[source] == "undefined") {
            this.data[source] = {
                source: source,
                company: [],
                voucher: [],
                challenge: [],
            };
        }
        if (companyId) {
            this.data[source].company.push(companyId);
        }
        if (id && typeof this.data[source][type] != "undefined") {
            this.data[source][type].push(id);
        }
    },

    send() {
        for (const key in this.data) {
            if (Object.hasOwnProperty.call(this.data, key)) {
                const obj = this.data[key];
                obj.company = uniqueArray(obj.company);
                obj.voucher = uniqueArray(obj.voucher);
                obj.challenge = uniqueArray(obj.challenge);
            }
        }

        const formData = new FormData();
        formData.append("log_impression", true);
        formData.append("impressions", JSON.stringify(this.data));

        if (navigator && navigator.sendBeacon) {
            const url = API.getEndpoint() + "/v1/log/impression";
            navigator.sendBeacon(url, formData);
        } else {
            API.request("log/impression", "POST", "", formData, "v1", "form");
        }
    },
};
