import { useContext, useEffect, useRef, useState } from "react";
import { config } from "./config";
import { isInViewport } from "./helper";
import { ImpressionLog } from "./impression_log";
import { IntlContext } from "./IntlContext";

export function useWindowWidth() {
    const [windowWidth, setWindowWidth] = useState(window ? window.innerWidth : 0);

    function handleWindowResize() {
        setWindowWidth(window.innerWidth);
    }

    useEffect(() => {
        handleWindowResize();

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [handleWindowResize]);

    return windowWidth;
}
export const useMediaQuery = (query) => {
    const media = window.matchMedia(query);
    const [matches, setMatches] = useState(media.matches);

    useEffect(() => {
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => setMatches(media.matches);
        window.addEventListener("resize", listener);
        return () => window.removeEventListener("resize", listener);
    }, [matches, query]);

    return matches;
};
export function useIsMounted() {
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);
    return isMounted;
}

export function useConstructor(callback = () => {}) {
    const [hasRun, setHasRun] = useState(false);
    if (!hasRun) {
        callback();
        setHasRun(true);
    }
}

// This returns a ref, which you should set on the element that you want to log
// Once the element comes into view it will be logged once
export function useImpressionLogging({ source, companyId, type, id }) {
    const ref = useRef(null);
    const [isLogged, setLogged] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", scroll, true);
        scroll();
        return () => {
            window.removeEventListener("scroll", scroll, true);
        };
    }, [isLogged]);

    function scroll() {
        if (!isLogged && ref && ref.current && isInViewport(ref.current, window, 0)) {
            setLogged(true);
            ImpressionLog.add(source, companyId, type, id);
        }
    }

    return ref;
}

export function usePlatformUrl(route) {
    const intlContext = useContext(IntlContext);
    const lang = intlContext.getLanguage();
    const country = intlContext.getCountry();
    let routes = platformRoutes[lang];
    if (!routes) {
        routes = platformRoutes.en;
    }

    const baseUrl = country === "de" ? config("platform_url_de") : config("platform_url");
    return baseUrl + "/" + (routes[route] || "");
}

export function getPlatformUrlByTld(route, tld) {
    let routes = platformRoutes[tld];
    if (!routes) {
        routes = platformRoutes.en;
    }

    const baseUrl = config("platform_url_placeholder").replace("{tld}", tld);
    return baseUrl + "/" + (routes[route] || "");
}

export function metaTitle(title) {
    document.title = title + " - iamstudent";
}
export function defaultMetaTitle() {
    document.title = "iamstudent - For a student life without limits.";
}
const platformRoutes = {
    de: {
        logout: "user/logout",
        privacy: "datenschutz-dsgvo",
        terms: "agb",
    },
    en: {
        logout: "user/logout",
        privacy: "privacy",
        terms: "terms",
    },
};
