import React from "react";
import svg from "../assets/iamstudent.svg";

export function IamStudentLogo({ width = 100, withText = "", className = null }) {
    const theClass = "has-text-centered is-size-7" + (className ? " " + className : "");
    return (
        <div className={theClass}>
            <span
                dangerouslySetInnerHTML={{ __html: svg }}
                alt="iamstudent Logo"
                style={{ width: width }}
                className="is-inline-block"
            ></span>
            {withText && <p>{withText}</p>}
        </div>
    );
}
