import { IoLanguageOutline } from "react-icons/io5";
import React, { useEffect } from "react";
import { API } from "../core/api";
import { IntlContext } from "../core/IntlContext";
import { LocalStorage } from "../core/storage";
import { Icon } from "./Icon";
import { useTrans } from "./Trans";
import styled from 'styled-components';
import { toast } from "react-hot-toast";

export function LanguageSelector({ onChange, textColor }) {
    const Intl = React.useContext(IntlContext);
    const activeLang = Intl.getLanguage();
    const languages = Intl.getSupportedLanguages().map((value) => {
        return { name: getLanguageName(value), code: value };
    });

    return (
        <>
            <LangPicker className="control has-icons-left">
                <div
                    className="select is-small is-flex is-align-items-center has-text-grey-dark"
                    style={{ height: "100%" }}
                >
                    <select
                        onChange={handleChange}
                        value={activeLang}
                        style={{ border: "1px transparent", backgroundColor: "transparent", color: textColor ?? "unset" }}
                        className=" is-size-7"
                    >
                        {languages.map((lang) => (
                            <option key={lang.code} value={lang.code}>
                                {lang.name}
                            </option>
                        ))}
                    </select>
                    <div style={{color: textColor }}>
                    <Icon
                        icon={IoLanguageOutline}
                        className={`is-left is-small color-unset`} 
                    /></div>
                </div>
            </LangPicker>
            <SuccessMessage lang={activeLang} />
        </>
    );

    function handleChange(e) {
        const newLang = e.target.value;
        Intl.setLanguage(newLang);

        const token = API.getToken();
        if (token) {
            API.request(
                "user/language",
                "PUT",
                token,
                JSON.stringify({
                    lang: newLang,
                })
            );
        }

        LocalStorage.set("lang", newLang);

        if (onChange) {
            onChange(newLang);
        }
    }
}

function SuccessMessage({ lang }) {
    const successMessage = useTrans("toast.success.language");
    const [lastLang, setLastLang] = React.useState(lang);

    useEffect(() => {
        if (lastLang !== lang) {
            toast.success(successMessage, {
                position: "top-right",
            });
            setLastLang(lang);
        }
    }, [lang]);

    return null;
}

function getLanguageName(code) {
    switch (code) {
        case "de":
            return "Deutsch";
        case "en":
            return "English";
        case "fr":
            return "Français";
        case "nl":
            return "Nederlands";
        case "it":
            return "Italiano";
        case "cs":
            return "Česky";
        case "da":
            return "Dansk";
        case "fi":
            return "Suomi";
        case "pl":
            return "Polski";
        case "sv":
            return "Svenska";
        case "es":
            return "Español";
        case "pt-PT":
            return "Português lusitano";
    }
    return "en";
}
const LangPicker = styled.div`
.select:after{
    content: unset !important;
}

`
