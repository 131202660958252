import { Form, Formik } from "formik";
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { PrimaryButton } from "./Button";
import { Divider } from "./Divider";
import { FacebookButton } from "./FacebookButton";
import { FormTextInput } from "./form/Input";
import { Link, NavLink } from "./Link";
import { Modal, ModalFooterInfo } from "./Modal";
import { Trans, useTrans } from "./Trans";
import { WhatIsIamStudent } from "./WhatIsIamStudent";
import { InAppBroserNotification } from "./notification/InAppBrowserNotification";
import { emailSchema } from "../views/Registration/Registration/validation";
import { GoogleLogin } from "@react-oauth/google";
import { config } from "../core/config";
import AppleLogin from "react-apple-login";
import { authContext } from "../core/auth";

export function RegisterModal({ baseUrl = "", loginPathOrFunc = "/user/login" }) {
    const [width, setWidth] = useState(0);
    const [oauthLoading, setOauthLoading] = useState();
    const [registerError, setRegisterError] = useState("");

    const ref = useRef(null);
    const auth = useContext(authContext);
    const {
        oauth,
        register,
        error,
        loading,
        authProviders,
        appleRedirectUrl,
        googleAutoload,
        fbAutoload,
        inAppBrowser,
        initEmail,
        close,
        locale,
        appleAutoload,
        parsed,
    } = auth;
    // look if we have an error from the facebook redirect
    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    });
    useEffect(() => {
        if (error.error) {
            setRegisterError({ error: error.error, provider: error.provider });
        }
    }, [error]);
    // autofill email
    return (
        <>
            <Modal isVisible={true} onClose={close ?? null} maxWidth={460}>
                <h1 className="title is-4">
                    <Trans id="register.title" />
                </h1>
                <p className="subtitle is-6 is-measure">
                    <Trans id="register.subtitle" />
                </p>

                <div ref={ref} style={{ maxWidth: 375, margin: "auto" }}>
                    {parsed && (
                        <>
                            <div style={{ height: "40px" }}>
                                <FacebookButton
                                    autoLoad={fbAutoload}
                                    label={useTrans("oauth.fb.button.label")}
                                    onResponse={(response) => {
                                        if (response.status !== "unknown") {
                                            oauth(response, authProviders.facebook);
                                            setOauthLoading(false);
                                        } else {
                                            setRegisterError({ error: response.status, provider: authProviders.facebook });
                                            setOauthLoading(false);
                                        }
                                    }}
                                    loading={oauthLoading === authProviders.facebook || loading === authProviders.facebook}
                                    disabled={inAppBrowser}
                                    onClick={(e) => {
                                        setOauthLoading(authProviders.facebook);
                                        setRegisterError({ error: "", provider: "" });
                                    }}
                                />
                            </div>
                            {registerError.error && registerError.provider === authProviders.facebook && (
                                <span className="help is-danger">
                                    <Trans id={registerError.error} />
                                </span>
                            )}
                            <div style={style.button} className="has-background-black">
                                <AppleLogin
                                    clientId="com.iamstudent.applelogin"
                                    redirectURI={appleRedirectUrl}
                                    designProp={{ width: width, height: 40, border_radius: 0, locale: locale }}
                                    usePopup={true}
                                    callback={(response) => {
                                        oauth(response, authProviders.apple);
                                    }}
                                    responseMode="form_post"
                                    responseType="code id_token"
                                    scope="email name"
                                    autoLoad={appleAutoload}
                                />
                            </div>
                            <div style={style.button}>
                                <GoogleLogin
                                    label={<Trans id="register.google" />}
                                    className="mt-3"
                                    type="standart"
                                    onSuccess={(response) => {
                                        oauth(response, authProviders.google);
                                    }}
                                    onError={(error) => {
                                        setRegisterError({ error: error.key, provider: authProviders.google });
                                        setOauthLoading(false);
                                    }}
                                    loading={oauthLoading === authProviders.google || loading === authProviders.google}
                                    disabled={inAppBrowser}
                                    onClick={() => {
                                        setRegisterError({ error: "", provider: "" });
                                        setOauthLoading(authProviders.google);
                                    }}
                                    width={width}
                                />
                            </div>
                            {registerError.error && registerError.provider === "google" && (
                                <span className="help is-danger">
                                    <Trans id={registerError.error} />
                                </span>
                            )}
                        </>
                    )}

                    {inAppBrowser && <InAppBroserNotification inAppBrowser={inAppBrowser} />}
                </div>

                <Divider content={<Trans id="default.or" />} />
                {parsed && (
                    <Formik
                        initialValues={{
                            email: initEmail ?? "",
                        }}
                        validationSchema={emailSchema}
                        onSubmit={(values) => {
                            register(values.email);
                        }}
                    >
                        <Form noValidate>
                            <FormTextInput
                                name="email"
                                label={<Trans id={"user.login.form.label.email"} />}
                                type="email"
                                customError={registerError.provider === authProviders.default ? registerError.error : null}
                            />
                            <PrimaryButton
                                type="submit"
                                loading={loading === authProviders.default}
                                disabled={loading === authProviders.default}
                            >
                                <Trans id="user.register" />
                            </PrimaryButton>
                        </Form>
                    </Formik>
                )}

                <p className="is-size-7 mt-4">
                    <Trans id="register.already_registered" />
                    &nbsp;
                    {loginPathOrFunc && typeof loginPathOrFunc === "string" && (
                        <NavLink to={baseUrl + loginPathOrFunc}>
                            <Trans id="user.login" />
                        </NavLink>
                    )}
                    {loginPathOrFunc && typeof loginPathOrFunc === "function" && (
                        <Link onClick={loginPathOrFunc}>
                            <Trans id="user.login" />
                        </Link>
                    )}
                    <br></br>
                    <ForgotPassword />
                </p>

                <ModalFooterInfo>
                    <WhatIsIamStudent />
                </ModalFooterInfo>
            </Modal>
        </>
    );

    function ForgotPassword() {
        const forgotPasswordLink = `${config("international_platform_url")}/user/lostpassword`;
        console.log(forgotPasswordLink);
        return (
            <Trans
                id={"user.login.lostpassword.info"}
                values={{
                    supportLink: (
                        <Link to={forgotPasswordLink} openNewTab>
                            <Trans id="user.lostpassword" />
                        </Link>
                    ),
                }}
            />
        );
    }
}
const style = {
    button: {
        width: "100%",
        height: "40px",
        borderRadius: "2.25px",
        cursor: "pointer",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
    },
};
