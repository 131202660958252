import React, { useContext, useState } from "react";
import { DefaultFooter, IamStudentLogo, Icon, Link, useTrans } from "../../base";
import styled, { ThemeContext } from "styled-components";
import { BsChevronLeft } from "react-icons/bs";
import { DefaultHeader } from "base";
import { useLocation, useMatch } from "react-router-dom";
import { useEffect } from "react";

export function StandaloneView({ appInfo, children }) {
    const [company, setCompany] = useState(null);
    const [title, setTitle] = useState(null);
    const [url, setUrl] = useState(null);
    const [hasFooter, setHasFooter] = useState(true);
    const match = useMatch("/:country/:company/*");
    const titleTrans = useTrans("standalone.title", { company: title });
    const location = useLocation();
    const pathname = location.pathname;
    useEffect(() => {
        if (appInfo) {
            setCompany(appInfo.company);
            setTitle(appInfo.company.nickname);
            setUrl(appInfo.company.website);
        }
        if (match) {
            setHasFooter(false);
        } else {
            setHasFooter(true);
        }
    });
    return (
        <>
            <div className={"container"}>
                {!pathname.includes("support") && <StandaloneHeader company={company} title={title} url={url} />}
            </div>
            <div className={"container"} style={{ border: "1px solid #dbdbdb" }}>
                {children}
            </div>
            {hasFooter && (
                <div className={"container"} style={{ border: "1px solid #dbdbdb" }}>
                    <DefaultFooter />
                </div>
            )}
        </>
    );
}

export function StandaloneHeader({ company, title, url, className }) {
    const theme = useContext(ThemeContext);
    return (
        <Header className={className}>
            <div className="hero-body" style={{ paddingLeft: 0, paddingRight: 0, overflow: "hidden" }}>
                <div className="columns is-multiline is-mobile is-flex is-vcentered">
                    <div className="column is-one-fifth-desktop is-three-fifths-touch">
                        <Link to={url}>
                            <Icon icon={BsChevronLeft} size={"1.5rem"} className={"is-pulled-left mt-3 mr-3"} />
                            {company && company.logo && (
                                <>
                                    <figure className="image is-48x48 is-pulled-left">
                                        <img
                                            height={48}
                                            width={48}
                                            alt={title}
                                            className="has-text-left-desktop"
                                            src={company.logo + "?height=48&width=48&mode=fill&sharp=false"}
                                        />
                                    </figure>
                                </>
                            )}
                            {company && !company.logo && <span className={"button is-text mt-1"}>Back to {company.nickname}</span>}
                        </Link>
                    </div>

                    {/*Mobile iamstudent logo*/}
                    <div className="column is-one-fifth-desktop is-two-fifths-touch is-hidden-desktop">
                        <IamStudentLogo className="pt-3" width={100} />
                    </div>

                    <div className="column is-three-fifths-desktop  has-text-centered is-full-touch">
                        <h1 className="title  is-4 ">{title}</h1>
                    </div>

                    {/*Desktop iamstudent logo*/}
                    <div className="column is-one-fifth-desktop is-hidden-touch">
                        {company && company.permalink && (
                            <Link
                                to={
                                    "https://www.iamstudent.com?utm_source=verify&utm_medium=web&utm_campaign=verify_standalone_logo_" +
                                    company.permalink
                                }
                            >
                                <IamStudentLogo className="has-text-right pt-3" width={100} />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </Header>
    );
}

// not sure why we can't use props for background:
// ${(props) => props.theme.color.grayLight}
const Header = styled.header.attrs((props) => ({
    className: "hero is-small",
}))`
    background: #fff;
    // margin-top: 50px;
    color: ${(props) => props.theme.color.text};
`;
