export const routes = {
    userLogin: "user/login",
    userRegister: "user/register/",
    register: "register/:email",
    registerDone: "/register/done/success",
    thanks: "register/thanks/:email",
    activation: "user/activate/:activationKey",
    verify: "user/verify/:code/:exp/:token",
    password: "user/password/:activationKey",
    support: "support",
    organisation: "/organisation/request",
};
