import { GoogleOAuthProvider } from "@react-oauth/google";
import React, { useContext } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { config, LoginModal } from "../../../base";
import { BaseUrlContext } from "../../../base/components/baseprovider/BaseUrlProvider";
import { AuthProvider } from "../../../base/core/auth";
import { routes } from "../../routes";

function UserLogin({ loginFunc, isLoggedIn, fbLoginFunc, googleLoginFunc }) {
    const match = useMatch("/:country/:organisation/*");
    const baseUrl = match.pathnameBase;
    const registerPath = "/user/register";
    const history = useNavigate();
    console.log("UserLogin", { baseUrl, registerPath, match });
    return (
        <GoogleOAuthProvider clientId={config("google_client_id")}>
            <AuthProvider
                onClose={() => {
                    history(baseUrl);
                }}
                registerPathOrFunc={registerPath}
                baseUrl={baseUrl}
            >
                <LoginModal isLoggedIn={isLoggedIn} />
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

export default UserLogin;
