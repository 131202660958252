import React from "react";

export function Icon({ className, icon, size = "1rem" }) {
    const iconClasses = className ? "icon " + className : "icon";
    const TheIcon = icon;
    return (
        <span className={iconClasses}>
            <TheIcon size={size} />
        </span>
    );
}

export function FlagIcon({ country, size = "1rem", className }) {
    return (
        <img
            src={"https://flagcdn.com/" + country + ".svg"}
            data-gumlet="false"
            alt={country}
            className={`mr-1${className ? " " + className : ""}`}
            style={{
                width: size,
            }}
        />
    );
}
