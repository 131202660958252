import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { GA } from "../core/google_analytics";
import { isBrowser } from "../core/helper";
import { LocalStorage } from "../core/storage";
import { UserVerificationState } from "../core/User";

export function OnVerifiedNotification({ userStatus, successMessage }) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (userStatus === UserVerificationState.VERIFIED && LocalStorage.get("verification_message")) {
            setActive(true);
            LocalStorage.remove("verification_message");

            const { hide } = toast.success(<div className="is-success is-size-6">{successMessage}</div>, {
                position: "top-center",
                hideAfter: 0,
                onClick: () => {
                    hide();
                    setActive(false);
                },
            });
        }
    }, [userStatus]);

    const handleClick = () => {
        if (isBrowser()) {
            // cogoToast doesn't seem to have a way to programmatically close a toast
            document.getElementById("ct-container").remove();
            setActive(false);
        }
    };

    if (active) {
        return <div style={style} role="button" aria-label="Close" onKeyDown={() => handleClick()} onClick={() => handleClick()}></div>;
    } else {
        return null;
    }
}

const style = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0, 20, 0, 0.3)",
    position: "fixed",
    cursor: "pointer",
};
