import { useEffect } from "react";
import { animScrollTop, immediateScrollTop } from "../core/helper";

export function ScrollTop({ animated = false, topPosition = 0 }) {
    useEffect(() => {
        if (animated) {
            animScrollTop(topPosition);
        } else {
            immediateScrollTop(topPosition);
        }
    }, []);

    return null;
}
