import React from "react";
import styled from "styled-components";
import { clamp } from "../core/helper";

const stepsActiveBg = "#20cb55";
const stepsInactiveBg = "#d6d6d6";

export function Steps({ currentStep, maxSteps, descriptions = [] }) {
    let steps = new Array(maxSteps);
    for (let index = 0; index < maxSteps; index++) {
        steps[index] = descriptions[index] || index + 1;
    }

    currentStep = clamp(currentStep, 0, maxSteps - 1);

    return (
        <StepList>
            {steps.map((value, index) => {
                return <StepSegment index={index} activeIndex={currentStep} value={value} key={"step" + index} />;
            })}
        </StepList>
    );
}

function StepSegment({ index, activeIndex, value }) {
    const isActive = index === activeIndex;
    const wasActive = index <= activeIndex;

    return (
        <StepSegmentStyled isActive={isActive} wasActive={wasActive}>
            <div>
                <Marker isActive={isActive} wasActive={wasActive}>
                    {isActive ? <strong>{index + 1}</strong> : <span>{index + 1}</span>}
                </Marker>
                <Content>{isActive ? <strong>{value}</strong> : <span>{value}</span>}</Content>
            </div>
        </StepSegmentStyled>
    );
}

const StepList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 1rem;
`;

const Marker = styled.div`
    background-color: ${(props) => (props.wasActive ? stepsActiveBg : stepsInactiveBg)};
    color: white;
    position: relative;
    height: 2rem;
    width: 2rem;
    left: calc(50% - 1rem);
    overflow: hidden;
    display: block;
    border-radius: 50%;
    z-index: 3;
    transform: ${(props) => (props.isActive ? "scale(1.33)" : "scale(1.0)")};
    transition: all 0.25s ease-out;
    font-weight: bold;

    & > * {
        display: block;
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const StepSegmentStyled = styled.li`
    position: relative;
    flex-basis: 1rem;
    flex-grow: 1;
    flex-shrink: 1;

    &:not(:last-child):after {
        display: block;
        content: "";
        position: absolute;
        height: 0.4em;
        left: 50%;
        right: -50%;
        top: calc(1rem - (0.2em));
        background-color: ${(props) => (props.wasActive && !props.isActive ? stepsActiveBg : stepsInactiveBg)};
        transition: all 0.25s ease-out;
    }
`;

const Content = styled.div`
    text-align: center;
    margin-top: 0.5em;

    & > * {
        color: inherit;
    }
`;
