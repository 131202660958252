import React from "react";
import { FormTextInput } from "../../../components/form/Input";
import { Trans, useTrans } from "../../../components/Trans";

export function MatriculationNrInput({
    organisation,
    setUniMailAutovalue,
    matriculationNumberIsVerifying,
    matriculationNumberIsValid,
    setMatriculationNumberIsValid,
}) {
    const placeholderText = useTrans("register.form.placeholder.matriculation_number");

    if (organisation && organisation.disableMatriculationNumber === false) {
        return (
            <FormTextInput
                name="matriculation_number"
                label={<Trans id="register.form.label.matriculation_number" />}
                placeholder={placeholderText}
                isLoading={matriculationNumberIsVerifying}
                onChange={onChange}
                isSuccess={matriculationNumberIsValid}
            />
        );
    } else {
        return null;
    }

    function onChange(e) {
        if (organisation && organisation.syntax && organisation.tld) {
            const matriculation_number = e.target.value;
            const uniMail = organisation.syntax.replace("%s", matriculation_number) + "@" + organisation.tld;
            setUniMailAutovalue(uniMail);
        }
        setMatriculationNumberIsValid(false);
    }
}
