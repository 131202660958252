import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import { Link } from "./Link";

export function Toggle({ label, className = "", children }) {
    const [visible, setVisible] = useState(false);

    return (
        <span className={className}>
            <Link onClick={toggle}>{label}</Link>
            <AnimatePresence>
                <div key={visible}>
                    <motion.div
                        initial="closed"
                        animate="open"
                        exit="closed"
                        variants={variants}
                        transition={{
                            type: "tween",
                            ease: "easeInOut",
                            duration: 0.3,
                        }}
                        style={{
                            overflow: "hidden",
                        }}
                    >
                        {visible && children}
                    </motion.div>
                </div>
            </AnimatePresence>
        </span>
    );

    function toggle(e) {
        e.preventDefault();
        setVisible(!visible);
    }
}

const variants = {
    open: {
        x: 0,
        height: "auto",
        opacity: 1.0,
    },
    closed: {
        x: 30,
        height: 0,
        opacity: 0.0,
    },
};
