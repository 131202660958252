import React, { useState } from "react";
import { getUniversities } from "../../../api/organisation";
import { FormAutocomplete } from "../../../components/form/Autocomplete";
import { FlagIcon } from "../../../components/Icon";
import { Trans, useTrans } from "../../../components/Trans";
import { MyStudyProgramIsNotThere } from "./MyStudyProgramIsNotThere";

export function OrganisationSelector({
    country,
    setCountry,
    organisation,
    setOrganisation,
    courseId,
    setCourseId,
    setUniMailAutovalue,
    setUniMailIsWriteable,
}) {
    const [organisationStudyProgramOverride, setOrganisationStudyProgramOverride] = useState(undefined);

    const [courseTitle, setCourseTitle] = useState("");
    const [showStudyProgramsWarning, setShowStudyProgramsWarning] = useState(false);

    const showStudyPrograms = organisation && organisation.studyPrograms && organisation.studyPrograms.length > 0;
    const organisationPlaceholder = useTrans("register.form.placeholder.university");
    const studyProgramPlaceholder = useTrans("register.form.placeholder.study_program");

    if (organisation && organisation.length) {
        setOrganisationFromParam(organisation);
    }

    return (
        <>
            <FormAutocomplete
                id="organisation"
                name="organisation"
                className="organisation"
                label={<Trans id="default.university" />}
                placeholder={organisationPlaceholder}
                getSuggestions={getSuggestions}
                getSuggestionValue={(suggestion) => suggestion.name}
                onSelected={onSelected}
                isSuccess={organisation !== null}
                requestLimitRate={250}
                resultLimitRate={300}
                iconLeft={organisation && organisation.country && <Flag country={organisation.country} />}
                validateOnBlur={true}
            />

            {country && (
                <p className="is-size-7 mb-5 has-text-grey-darker">
                    <Trans id="register.universities.in" />
                    &nbsp;
                    <b>{useTrans(`country.${country}`)}</b>&nbsp;&middot;&nbsp;
                    <a onClick={() => setCountry(null)}>
                        <Trans id="register.universities.in.disable" />
                    </a>
                </p>
            )}

            {showStudyPrograms && (
                <FormAutocomplete
                    id="organisation_study_program"
                    name="organisation_study_program"
                    className="organisation_study_program"
                    label={<Trans id="register.form.label.study_program" />}
                    placeholder={studyProgramPlaceholder}
                    onSelected={(suggestion) => {
                        setCourseId(suggestion.id);
                        setShowStudyProgramsWarning(false);
                    }}
                    onChange={(e, value) => {
                        setCourseId(null);
                        setCourseTitle(value);
                    }}
                    onFocus={(e) => {
                        setOrganisationStudyProgramOverride(undefined);
                    }}
                    onBlur={(e) => {
                        setShowStudyProgramsWarning(courseId === null);
                    }}
                    getSuggestions={getStudyProgramSuggestions}
                    isSuccess={courseId !== null}
                    getSuggestionValue={(suggestion) => suggestion.title}
                    overrideValue={organisationStudyProgramOverride}
                    validateOnBlur={true}
                />
            )}

            {showStudyPrograms && showStudyProgramsWarning && <MyStudyProgramIsNotThere />}
        </>
    );

    function setOrganisationFromParam(organisation) {
        getUniversities(organisation, country, 1).then((response) => {
            const results = response.filter((item) => item.name === organisation);
            if (results.length === 1) {
                setOrganisation(results[0]);
                setUniMailIsWriteableByOrganisation(results[0]);
            } else {
                setOrganisation(null);
                setUniMailAutovalue(null);
            }
        });
    }

    function getSuggestions(value) {
        if (!value || value.length === 0) return [];
        setOrganisation(null);
        setUniMailAutovalue(null);

        function getUnis() {
            return getUniversities(value, country).then((response) => {
                const results = response.filter((item) => item.name === value);
                if (results.length === 1) {
                    setOrganisation(results[0]);
                }
                return Promise.resolve(response);
            });
        }

        return getUnis();
    }

    function onSelected(org) {
        setOrganisation(org);
        setOrganisationStudyProgramOverride("");
        setCourseId(null);
        setCountry(org.country);

        setUniMailIsWriteableByOrganisation(org);
    }

    function setUniMailIsWriteableByOrganisation(org) {
        setUniMailIsWriteable(true);

        if (org.syntax && org.tld) {
            const uniMail = org.syntax.replace("%s", "") + "@" + org.tld;
            setUniMailAutovalue(uniMail);
            setUniMailIsWriteable(false);
        }
    }

    function getStudyProgramSuggestions(value) {
        if (!value || value.length === 0) return [];
        const lowercaseValue = value.toLowerCase();
        return organisation.studyPrograms.filter((program, index) => {
            const programTitle = program.title.toLowerCase();
            return programTitle.indexOf(lowercaseValue) !== -1;
        });
    }
}

function Flag({ country }) {
    return (
        <FlagIcon country={country.toLowerCase()} className="is-small is-absolute is-left is-top ml-3 mt-4 has-text-dark" height="100%" />
    );
}
