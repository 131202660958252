import { NotificationBar, UserContext, UserVerificationState } from "base";
import { VerificationStatusContent } from "base/views";
import React, { Suspense, useContext } from "react";

function VerificationBar({ company, userInfo }) {
    const user = useContext(UserContext);
    if (user.verificationStatus === UserVerificationState.NULL || user.verificationStatus === UserVerificationState.VERIFIED) {
        return null;
    }

    return (
        <NotificationBar>
            <div className="is-flex-tablet is-justify-content-center is-align-items-center">
                <Suspense fallback={null}>
                    <VerificationStatusContent supportUrl={company + "/support"} userInfo={userInfo} />
                </Suspense>
            </div>
        </NotificationBar>
    );
}

export default VerificationBar;
