import React from "react";
import { Divider, Trans } from "../../base";

export const VoucherNumber = ({ info }) => {
    const date = new Date(info.created);
    return (
        <div>
            <p>
                <Trans id={"your.vouchercode"} />
            </p>
            <p className="has-text-weight-bold">{info.voucherNumber}</p>
            <div className="my-3"></div>
            {info.created && (
                <p className="is-size-7">
                    <Trans id={"first.time.seen"} /> {date.toLocaleString()}
                </p>
            )}
        </div>
    );
};
