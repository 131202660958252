import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "./User";
import { API } from "./api";
import jwt_decode from "jwt-decode";
import { appendTrailingSlash, isInAppBrowser } from "./helper";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useTrans } from "../components/Trans";
import { config } from "./config";
import { IntlContext } from "./IntlContext";
export const authContext = React.createContext();

export function buildUrl(basePath, email, provider, token, redirectUrl, organisation) {
    console.log(basePath, email, provider, token, redirectUrl, organisation);
    let path = appendTrailingSlash(basePath) + "register/" + email;
    if (provider) {
        path += "?" + provider + "=true&token=" + token;
    }

    if (redirectUrl) {
        let c = token ? "&" : "?";
        path += c + "redirect_url=" + encodeURIComponent(redirectUrl);
    }

    if (organisation) {
        let c = token || redirectUrl ? "&" : "?";
        path += c + "organisation=" + organisation;
    }

    return path;
}
export function AuthProvider({
    children,
    onClose,
    baseUrl = "",
    loginPathOrFunc = "/user/login",
    registerPathOrFunc = "/register",
    thanksUrl,
}) {
    const User = useContext(UserContext);
    const navigate = useNavigate();
    const [error, setError] = useState({ provider: null, error: null });
    const [loading, setLoading] = useState(false);
    const [inAppBrowser, setInAppBrowser] = useState(false);
    const [params, setParams] = useState({
        googleAutoload: false,
        fbAutoload: false,
        appleAutoload: false,
        redirectUrl: null,
        organisation: null,
        initEmail: null,
        isOAuth: null,
        parsed: false,
    });
    const location = useLocation();
    const loginInvalid = useTrans("user.error.login.invalid");
    const intl = useContext(IntlContext);
    const locale = intl.lang === "de" ? "de_DE" : "en_US";
    console.log(locale);
    const authProviders = {
        google: "google",
        facebook: "fb",
        apple: "apple",
        default: "default",
    };

    const auth = {
        authProviders: authProviders,
        user: User,
        error: error,
        loading: loading,
        loginPathOrFunc: loginPathOrFunc,
        registerPathOrFunc: registerPathOrFunc,
        thanksUrl: thanksUrl,
        appleRedirectUrl: config("apple_redirect_uri"),
        baseUrl: baseUrl,
        googleAutoload: params.googleAutoload,
        fbAutoload: params.fbAutoload,
        appleAutoload: params.appleAutoload,
        redirectUrl: params.redirectUrl,
        organisation: params.organisation,
        inAppBrowser: inAppBrowser,
        initEmail: params.initEmail,
        locale: locale,
        parsed: params.parsed,
        close,
        oauth,
        login,
        register,
    };
    function goToOauthConfirm() {
        navigate(`/oauth/confirm` + location.search);
    }
    function getSearchParams() {
        let googleAutoload = false;
        let fbAutoload = false;
        let redirectUrl = null;
        let organisation = null;
        let initEmail = null;
        let isOAuth = null;
        let appleAutoload = false;
        const parsed = queryString.parse(location.search);
        if (parsed) {
            if (parsed.google) {
                googleAutoload = true;
            }
            if (parsed.fb) {
                fbAutoload = true;
            }
            if (parsed.apple) {
                appleAutoload = true;
            }
            if (parsed.redirect_url) {
                redirectUrl = parsed.redirect_url;
            }
            if (parsed.organisation) {
                organisation = parsed.organisation;
            }
            if (parsed.email) {
                initEmail = parsed.email;
            }
            if (parsed.app_id) {
                isOAuth = true;

                if (isLoggedIn) {
                    goToOauthConfirm();
                }
            }
        }
        setParams({ googleAutoload, fbAutoload, redirectUrl, organisation, initEmail, isOAuth, appleAutoload, parsed: true });
    }
    function close() {
        if (params.isOAuth) {
            goToOauthConfirm();
        } else {
            if (onClose) {
                onClose();
            }

            navigate(baseUrl);
        }
    }
    // standart login
    function login(username, password) {
        setLoading(authProviders.default);
        return API.request(
            "login_check",
            "POST",
            "",
            JSON.stringify({
                username: username,
                password: password,
            }),
            ""
        )
            .then((response) => {
                if (response) {
                    User.login(response);
                }
            })
            .catch((error) => {
                setError({ error: loginInvalid, provider: authProviders.default });
            })
            .finally(() => {
                setLoading(false);
            });
    }
    // standart register
    function register(email, provider, token) {
        setLoading(provider ?? authProviders.default);
        return API.request("validate/email", "POST", "", JSON.stringify({ email }))
            .then((response) => {
                const url = buildUrl(baseUrl, email, provider ?? null, token ?? null, params.redirectUrl, params.organisation);
                navigate(url);
            })
            .catch((error) => {
                let errorKey;
                if (error.status === 400 || error.key === "error.email.exists") {
                    errorKey = "error.email.exists";
                } else if (error?.errors?.children?.email?.errors[0]) {
                    errorKey = error.errors.children.email.errors[0];
                } else {
                    errorKey = error;
                }

                setError({
                    provider: provider ?? authProviders.default,
                    error: errorKey,
                });
            })
            .finally(() => setLoading(false));
    }
    // oauth login// register
    function oauth(oauthResponse, provider) {
        setLoading(provider);
        //Get User Token from response and decode it
        //credential is for google, accessToken is for facebook, authorization.id_token is for apple
        const responseToken = oauthResponse.credential || oauthResponse.accessToken || oauthResponse.authorization?.id_token;
        const decodedToken = responseToken && !oauthResponse.accessToken ? jwt_decode(responseToken) : null;
        console.log(responseToken, decodedToken);
        const apiProvider = provider === authProviders.facebook ? "facebook" : provider;
        //Check if user exists
        return API.request(
            "oauth/" + apiProvider + "/check",
            "POST",
            "",
            JSON.stringify({
                token: responseToken,
            }),
            ""
        )
            .then((response) => {
                if (response) {
                    //If user exists, login
                    User.login(response);
                    if (redirectUrl) {
                        window.location = redirectUrl;
                    }
                    close();
                }
            })
            .catch((error) => {
                //If user does not exist, register
                if (error.key === "user.notfound" || (error.status >= 400 && error.status < 500)) {
                    const email = decodedToken?.email || oauthResponse?.email;
                    if (!email) {
                        setError({ error: "oauth.email.error", provider: provider });
                        return;
                    }
                    register(email, provider, responseToken);
                } else {
                    setError({ error: error.key, provider: provider });
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }
    useEffect(() => {
        if (location.state && location.state.error) {
            setError({ error: location.state.error, provider: authProviders.facebook });
        }
        if (!loading.loading && params.initEmail) {
            register(params.initEmail);
        }
        getSearchParams();
        setInAppBrowser(isInAppBrowser());
    }, []);
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
