import { FaChevronLeft } from "react-icons/fa";
import React, { useEffect, useState } from "react";
import { useWindowWidth } from "../core/hooks";
import { Icon } from "./Icon";
import { NavLink } from "./Link";
import { Trans } from "./Trans";

export function StickyBackButton({ backUrl }) {
    const [percent, setPercent] = useState(0.0);
    const mobile = useWindowWidth() <= 768;

    useEffect(() => {
        function handleScroll(e) {
            const offY = window.scrollY || window.scrollYOffset;
            const percent = Math.min(offY / 200.0, 1.0);
            if (mobile) {
                setPercent(percent * percent * 0.75);
            } else {
                setPercent(0.0);
            }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const style = {
        color: "#111",
        background: "rgba(255,255,255, " + percent + ")",
        boxShadow: "rgba(56, 54, 54, " + percent * 0.5 + ") 0 0 5px 0px",
        position: "fixed",
        zIndex: 10,
        padding: 8,
        paddingRight: 16,
        left: 16,
        top: 16,
        border: 1,
    };

    return (
        <NavLink to={backUrl} elementStyle={style}>
            <div className="is-flex is-align-items-center">
                <Icon icon={FaChevronLeft} className="mr-1" />
                <Trans id={"default.back"} />
            </div>
        </NavLink>
    );
}
