import { FaChrome } from "react-icons/fa";
import { FaEdge } from "react-icons/fa";
import { FaFirefoxBrowser } from "react-icons/fa";
import { FaSafari } from "react-icons/fa";
import React from "react";
import { useMatch } from "react-router-dom";
import styled from "styled-components";
import manderl from "../../assets/iams_manderl_happy.png";
import { isBrowser } from "../../core";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { Trans } from "../Trans";

export function UnsupportedBrowserOverlay({
    supportUrl,
    children,
    shouldUseReactRouter,
}) {
    let match;

    if (isBrowser() && shouldUseReactRouter) {
        match = useMatch(supportUrl);
    } else {
        match = true;
    }

    if (isBrowserSupported() || match) {
        return children;
    }

    return (
        <>
            {children}
            <Background>
                <div className="m-4 has-text-centered">
                    <Content>
                        <h1 className="title is-4">
                            <Trans id="browser.unsupported.title" />
                        </h1>
                        <h2 className="subtitle is-6">
                            <Trans id="browser.unsupported.subtitle" />
                        </h2>
                        <div className="is-clearfix">
                            <img
                                className="is-pulled-left mr-4 is-hidden-mobile"
                                src={manderl}
                                data-gumlet="false"
                                style={{ width: 115 }}
                                alt=":)"
                            />
                            <p
                                className="is-pulled-left has-text-left has-text-info-dark is-measure-narrow"
                                style={{ maxWidth: "100%" }}
                            >
                                <Trans id="browser.unsupported.description" />
                            </p>
                        </div>
                        <ul className="mt-5 level box">
                            <BrowserIcon
                                name={"Chrome"}
                                icon={FaChrome}
                                url="https://www.google.com/chrome/"
                            />
                            <BrowserIcon
                                name={"Edge"}
                                icon={FaEdge}
                                url="https://www.microsoft.com/edge"
                            />
                            <BrowserIcon
                                name={"Firefox"}
                                icon={FaFirefoxBrowser}
                                url="https://www.mozilla.org/firefox/"
                            />
                            <BrowserIcon
                                name={"Safari"}
                                icon={FaSafari}
                                url="https://support.apple.com/downloads/safari"
                            />
                        </ul>
                        <p className="help has-text-grey-light">
                            <Trans id="browser.unsupported.contact" />
                            &nbsp;
                            <Link to={supportUrl}>
                                <Trans id="browser.unsupported.contact.action" />
                            </Link>
                        </p>
                    </Content>
                </div>
            </Background>
        </>
    );
}

function BrowserIcon({ name, icon, url }) {
    return (
        <li className="level-left">
            <Link
                to={url}
                className="is-flex is-vcentered level-item pt-2 has-text-dark"
            >
                <Icon icon={icon} className="mr-1 is-large" size="3rem" />
                {name}
            </Link>
        </li>
    );
}

function isBrowserSupported() {
    let isIE;

    if (isBrowser()) {
        isIE =
            navigator.userAgent.toUpperCase().indexOf("TRIDENT/") != -1 ||
            navigator.userAgent.toUpperCase().indexOf("MSIE") != -1;
    }

    return !isIE;
}

const Background = styled.div`
    background-color: rgba(32, 71, 212, 0.61);
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

const Content = styled.div`
    background: white;
    text-align: center;
    max-width: 700px;
    margin: 2rem auto;
    padding: 2rem;
`;
