import React from "react";
import { isBrowser, isMobileDevice } from "../core/helper";
import { PrimaryButton } from "./Button";
import { Divider } from "./Divider";
import { Trans } from "./Trans";

export function DeeplinkButton({ url }) {
    if (isMobileDevice()) {
        return (
            <>
                <PrimaryButton
                    className="mr-4"
                    onClick={() => {
                        if (isBrowser()) {
                            window.location.href = url;
                        }
                    }}
                >
                    Weiter in der App
                </PrimaryButton>
                <Divider content={<Trans id="default.or" />} />
            </>
        );
    }
    return null;
}
