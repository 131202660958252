import { isBrowser } from "./helper";

export const GA = {
    autoSendOptions: {},

    // these events will be sent with every LogEvent() automatically
    setAutoSendEvent(eventId, value) {
        this.autoSendOptions[eventId] = value;
    },

    logEvent(eventId, options = {}) {
        if (isBrowser() && window.dataLayer) {
            const event = {
                event: eventId,
                ...options,
                ...this.autoSendOptions,
            };

            window.dataLayer.push(event);
        }

        if (isBrowser() && typeof window._mtm !== "undefined" && typeof _mtm !== "undefined") {
            const event = {
                event: eventId,
                ...options,
                ...this.autoSendOptions,
            };

            _mtm.push(event);
        }
        console.log("GA: Send Event: " + eventId, options);
    },

    trackPageview() {
        if (typeof window.ga === "function") {
            window.ga("set", "page", window.location.href);
            window.ga("send", "pageview");
        }
    },
};
