import React from "react";
import { IntlProvider } from "react-intl";
import { isBrowser } from "./helper";
import { LocalStorage } from "./storage";
import queryString from "query-string";
import { getGeolocation } from "../api";

// If default translations are given they are loaded twice on change
// as the languages from props.languages are also used
const baseTranslations = {
    // en: () => import('../assets/translations/en.json'),
    // de: () => import('../assets/translations/de.json'),
};

const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
    constructor(props) {
        super(props);

        // redirect old urls
        let country = null;

        // Get country from URL e.g. iamstudent.com/at
        country = isBrowser() ? window.location.pathname.split("/")[1] || null : null;
        if (LocalStorage) {
            LocalStorage.set("urlCountry", country);
        }
        console.log({ country }, "<-- country via url 1");

        // Get country from locale in URL e.g. iamstudent.com/at-DE
        if (country && country.indexOf("-") !== -1) {
            const newCountry = country.split("-")[1].toLowerCase();
            const newUrl = isBrowser() ? window.location.pathname.replace("/" + country + "/", "/" + newCountry + "/") : "";

            if (isBrowser()) {
                window.history.replaceState(null, "", newUrl);
            }

            country = newCountry;
            console.log({ country }, "<-- country after set via locale");
        }

        // Unset country if it is clearly not a country parameter,
        // instead it is just the first part of the route
        if (country && country.length > 2) {
            country = null;
        }

        // No valid country given => check in query param
        if (!country) {
            //console.log({country}, '<-- no valid country via url');

            if (location.search) {
                const parsed = queryString.parse(location.search);

                if (parsed.country) {
                    country = parsed.country;
                    console.log({ country }, "<-- country from parsed query string");
                }
            }
        }

        // No valid country given => check if there is one stored in local storage
        if (!country) {
            country = LocalStorage.get("country");
            console.log({ country }, "<-- country from local storage");
        }

        // // @debug
        // country = null;
        //
        // if (!country) {
        //     getGeolocation()
        //         .then((response) => {
        //             if (response && response.countryCode) {
        //                 country = response.countryCode.toLowerCase();
        //                 //this.setCountry(country, false, false);
        //                 console.log({country}, '<-- country from geolocation');
        //             }
        //         })
        //         .catch((error) => {
        //             // fallback to url
        //         });
        // }

        // Set current country to local storage
        if (this.isValidCountry(country)) {
            LocalStorage.set("country", country);
            console.log({ country }, "<-- country SET to local storage");
        }

        // Fallback AT
        if (!country) {
            country = "at";
            console.log({ country }, "<-- country used from fallback");
        }

        console.log({ country }, "<-- country after work");

        // determine starting language
        let lang = LocalStorage.get("lang");
        if (!lang) {
            const locale = isBrowser() ? window.navigator.language || window.navigator.userLanguage : "";
            lang = locale ? locale.split("-")[0] : "en";

            // fallback
            if (!this.isValidLanguage(lang)) {
                lang = "en";
            }
        }

        this.state = {
            lang: lang,
            country: country,
            locale: null,
            messages: null,
            runtimeMessages: {},
            addMessage: this.addMessage.bind(this),
            setLanguage: this.setLanguage.bind(this),
            setCountry: this.setCountry.bind(this),
            getLanguage: this.getLanguage.bind(this),
            getCountry: this.getCountry.bind(this),
            getLocaleString: this.getLocaleString.bind(this),
            getSupportedLanguages: this.getSupportedLanguages.bind(this),
            isValidLanguage: this.isValidLanguage.bind(this),
        };
        this.setLanguage(lang);
    }

    addMessage(key, value) {
        this.setState({
            runtimeMessages: {
                [key]: value,
            },
        });
    }

    setLanguage(lang) {
        if (!this.isValidLanguage(lang)) {
            return;
        }

        if (this.props.translations) {
            Promise.all([
                baseTranslations[lang] ? baseTranslations[lang]() : Promise.resolve({}),
                this.props.translations[lang] ? this.props.translations[lang]() : Promise.resolve({}),
            ]).then((values) => {
                this.setState({
                    lang: lang,
                    messages: { ...values[0], ...values[1] },
                });
            });
        } else {
            baseTranslations[lang]().then((response) => {
                this.setState({
                    lang: lang,
                    messages: response,
                });
            });
        }
    }

    setCountry(country, reload = true, changeUrl = true) {
        this.setState({
            country: country,
        });
        LocalStorage.set("country", country);

        if (isBrowser() && changeUrl) {
            const newUrl = window.location.pathname.replace("/" + this.state.country + "/", "/" + country + "/");
            window.history.replaceState(null, "", newUrl);
        }

        if (isBrowser() && reload) {
            window.location.reload();
        }
    }

    getLanguage() {
        return this.state.lang;
    }

    getCountry() {
        return this.state.country;
    }

    getLocaleString() {
        return this.state.lang === "de" ? "de-DE" : "en-EN";
    }

    getSupportedLanguages() {
        const all = this.props.translations ? { ...this.props.translations, ...baseTranslations } : baseTranslations;
        return Object.keys(all);
    }

    getSupportedCountries() {
        return ["at", "de", "ch"];
    }

    isValidLanguage(lang) {
        const all = this.getSupportedLanguages();
        return all.indexOf(lang) !== -1;
    }

    isValidCountry(country) {
        const all = this.getSupportedCountries();
        return all.indexOf(country) !== -1;
    }

    render() {
        const { children } = this.props;
        const { lang, messages, runtimeMessages } = this.state;
        const allMessages = { ...messages, ...runtimeMessages };

        if (!this.state.messages) {
            return null;
        }

        return (
            <Context.Provider value={this.state}>
                <IntlProvider locale={lang} messages={allMessages} defaultLocale="de">
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export { IntlProviderWrapper, Context as IntlContext };
