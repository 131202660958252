import React, { useEffect, useState } from "react";
import { TOO_MANY_REQUESTS_EVENT_ID } from "../core";
import { Modal } from "./Modal";

export function TooManyRequestsInfo() {
    const [isVisible, setVisible] = useState(false);

    useEffect(() => {
        document.body.addEventListener(TOO_MANY_REQUESTS_EVENT_ID, (event) => {
            onEvent(event);
            return () => {
                document.body.removeEventListener(TOO_MANY_REQUESTS_EVENT_ID, onEvent);
            };
        });
    }, []);

    function onEvent(event) {
        setVisible(true);
    }

    return (
        <Modal isVisible={isVisible} maxWidth={400}>
            <h2 className="title is-4">Too many requests 🤖</h2>
            <p className=" subtitle help is-danger">Error 429: Please try again later.</p>
        </Modal>
    );
}
