import { schemaPage1, schemaPage2, schemaPage3, schemaPage4, validatePage1, validatePage2 } from "./validation";

export * from "./EmailOtherInput";
export * from "./MatriculationNrInput";
export * from "./MyOrganisationIsNotThere";
export * from "./OrganisationSelector";

export const schemaRegistrationPage1 = schemaPage1;
export const schemaRegistrationPage2 = schemaPage2;
export const schemaRegistrationPage3 = schemaPage3;
export const schemaRegistrationPage4 = schemaPage4;
export const validateRegistrationPage1 = validatePage1;
export const validateRegistrationPage2 = validatePage2;
