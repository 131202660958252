import { TOO_MANY_REQUESTS_EVENT_ID } from "./constants";

export function clamp(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

export function appendTrailingSlash(str) {
    const lastChar = str.substr(-1);
    if (lastChar !== "/") {
        return str + "/";
    }
    return str;
}

export function isHexColorDark(hexcolor) {
    if (hexcolor.indexOf("#") === -1) {
        return false;
    }
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 128;
}

export function hexToRgb(hex) {
    hex = String(hex).replace("#", "");
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return r + "," + g + "," + b;
}

export function rgbStringToHex(rgb) {
    // in case it's a hex color, return it
    if (rgb.indexOf("#") === 0) {
        return rgb;
    } else if (rgb.indexOf("rgb(") === 0) {
        const closingParen = rgb.indexOf(")");
        if (closingParen !== -1) {
            const [r, g, b] = rgb.slice(4, closingParen).split(",");
            return "#" + componentToHex(parseInt(r)) + componentToHex(parseInt(g)) + componentToHex(parseInt(b));
        }
    }
    return rgb;
}

export function componentToHex(c) {
    const hex = c.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}

export function immediateScrollTop(top = 0) {
    if (isBrowser()) {
        window.scroll(0, top);
    }
}

export function animScrollTop(top = 0) {
    if (isBrowser()) {
        requestAnimationFrame(() => {
            let targetPos = globalScrollY() * 0.75;
            if (targetPos < top + 0.5) {
                targetPos = top;
            }

            window.scroll(0, targetPos);

            if (targetPos !== top) {
                animScrollTop(top);
            }
        });
    }
}

export function globalScrollY() {
    if (isBrowser()) {
        return parseInt(window.scrollY || window.pageYOffset);
    }
}

export function showTooManyRequestInfo() {
    if (isBrowser()) {
        document.body.dispatchEvent(new Event(TOO_MANY_REQUESTS_EVENT_ID));
    }
}

export function isBrowser() {
    return typeof window !== "undefined";
}

export function isServer() {
    return typeof window === "undefined";
}

export function isInAppBrowser() {
    return /instagram|facebook/i.test(navigator.userAgent);
}

export function isMobileDevice() {
    if (isBrowser()) {
        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    } else {
        return false;
    }
}

export function uniqueArray(array) {
    return [...new Set(array)];
}

// usually the container will be window or body
export function isInViewport(element, container, threshold = 0) {
    if (isServer()) {
        return false;
    }

    const distance = element.getBoundingClientRect();

    if (container === window || container === document.body) {
        return (
            distance.top - threshold <= (window.innerHeight || document.documentElement.clientHeight) &&
            distance.bottom >= -threshold &&
            distance.left + distance.width >= 0 &&
            distance.left <= (window.innerWidth || document.documentElement.clientWidth)
        );
    } else {
        const rect = container.getBoundingClientRect();
        return (
            rect.top - threshold <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= -threshold &&
            distance.left + distance.width >= rect.left &&
            distance.left <= rect.left + rect.width
        );
    }
}

export function inIframe() {
    if (!isBrowser()) {
        return false;
    }
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

/**
 * This helper method maps search results items to "normal" API results
 *
 * @param results
 * @param userCountryTld
 * @returns {*[]|*}
 */

export function convertToItems(results, userCountryTld) {
    if (!results) {
        return [];
    }

    let userDomain = buildUserDomain(userCountryTld);

    return results.map((value) => {
        let rating = value.company.rating ? (userDomain ? value.company.rating[userDomain] : null) : null;
        return {
            id: value._id,
            image: value.image,
            rating: rating,
            country: value.country,
            company: value.company,
            brandlogo: value.company ? value.company.image : null,
            category: value.category ? value.category.name : null,
            descriptionCompact: value.sub || value.title || value.name,
            savingText: value.saving_text ? value.saving_text : null,
            endDate: value.end_date,
            isPlus: value.is_plus || false,
            isTop: value.is_sticky || false,
            isOnline: !value.is_expired,
            url: value.url,
            pubDate: value.start_date && value._type === "blogpost" ? value.start_date : null,
            buttonTitle: value.button_title,
            hideIfRegister: value.hideIfRegister,
            mobileImage: value.mobileImage ?? null,
        };
    });
}
export function convertToStudyProgramItems(results, userCountryTld) {
    if (!results) {
        return [];
    }
    return results.map((value) => {
        let link = "https://studiumfinden.com/studiengang/" + value.university.slug + "/" + value.slug;
        let rating = value.reviews_average ? { rating: value.reviews_average, count: value.reviews_count } : { rating: null, count: null };
        return {
            id: value.id,
            image: value.header_image ? value.header_image.url : null,
            rating: rating,
            company: value.university,
            brandlogo: value.university.logo ? value.university.logo.url : null,
            brandLogoBg: value.university ? value.university.logo_bg_color : null,
            descriptionCompact: value.name,
            isPremium: value.is_premium,
            url: link,
        };
    });
}
export function convertToUniversityItems(results, userCountryTld) {
    if (!results) {
        return [];
    }
    return results.map((value) => {
        let link = "https://studiumfinden.com/hochschule/" + value.slug;
        let rating = value.reviews_average ? { rating: value.reviews_average, count: value.reviews_count } : { rating: null, count: null };
        return {
            id: value.id,
            country: value.country,
            image: value.header_image ? value.header_image.url : null,
            rating: rating,
            brand: value.university ? value.university.name : null,
            brandlogo: value.logo ? value.logo.url : null,
            brandLogoBg: value.logo_bg_color,
            descriptionCompact: value.name,
            isPremium: value.is_premium,
            url: link,
            studyPrograms: value.study_programs_count,
            city: value.town,
            zip: value.zip,
        };
    });
}

/**
 * This helper method maps search results from type "company" items to "normal" API results
 *
 * @param results
 * @param userCountryTld
 * @returns {*[]|*}
 */

export function convertToCompanyItems(results, userCountryTld) {
    if (!results) {
        return [];
    }

    let userDomain = buildUserDomain(userCountryTld);

    return results.map((value) => {
        let rating = value.rating && userDomain ? value.rating[userDomain] ?? null : null;
        let offer = value.offers && userDomain ? (value.offers[userDomain] && value.offers[userDomain]["v"]) ?? null : null;
        let formattedOffers = [];

        if (offer && offer.saving > 0) {
            formattedOffers.push({
                saving: offer.saving.substring(0, offer.saving.length - 1),
                savingValueType: offer.saving.substring(offer.saving.length - 1) === "€" ? "euro" : "percent",
            });
        }

        return {
            id: value._id,
            logo: value.image,
            rating: rating,
            offers: formattedOffers,
            country: value.country,
            permalink: value.permalink,
            nickname: value.title || value.nickname,
        };
    });
}

export function buildUserDomain(countryTld, withPrefix = false, fallback = "com") {
    if (!countryTld) {
        return null;
    }

    countryTld = countryTld.toLowerCase();

    if (countryTld !== "at" && countryTld !== "de" && countryTld !== "ch") {
        countryTld = fallback;
    }

    if (process.env.NODE_ENV !== "production") {
        countryTld = "dev";
    }
    return (withPrefix ? "https://www." : "") + "iamstudent." + countryTld;
}
