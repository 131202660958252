export const VOUCHER_USE_TYPE = {
    MOBILE: 1,
    MOBILE_PIN: 2,
    MOBILE_PRINT: 3,
    PRINT: 4,
    ONLINE: 5,
    ONLINE_CODE: 6,
    ONLINE_UNIQUE_CODE: 7,
    ONLINE_QR_CODE: 8,
    ONLINE_UNIQUE_QR_CODE: 9,
    ONLINE_PAROLE_QR_CODE: 10,
};

export const TOO_MANY_REQUESTS_EVENT_ID = "event_too_many_requests";
