import { UserContext, useTrans } from "base";
import React, { useContext, useEffect, useState } from "react";
import { isBrowser } from "../base";
import AppRouter from "./views/AppRouter";

function App() {
    const [unsubscribeFromHistory, setUnsubscribeFromHistory] = useState(null);
    const user = useContext(UserContext);

    console.log(user);
    useEffect(() => {
        doAutologin();

        return () => {
            if (unsubscribeFromHistory) {
                unsubscribeFromHistory();
            }
        };
    }, []);

    useEffect(() => {
        doAutologin();
    }, [user.isLoggedIn, user.verificationState]);

    useEffect(() => {
        handleLocationChange(location);
    }, [location]);

    // render
    return (
        <>
            <AppRouter user={user} />
        </>
    );

    function doAutologin() {
        if (user && !user.isLoggedIn && location.state && location.state.shouldLogin) {
            user.autologinWithToken().catch((error) => {});
        }
    }

    function handleLocationChange(location) {
        if (window.updateHeight) {
            setTimeout(window.updateHeight, 1);
        }

        // mitigates duplicate css loading problem (bulma loaded twice in head)
        // TODO: find a proper solution for this hack
        // we can only do this because we only ever load 1 css file
        // NOTE: this causes the site to sometimes not load at all

        // mitigateDuplicateCss();

        function mitigateDuplicateCss() {
            const app = document.getElementById("App");
            if (!app || app.children.length === 0 || app.children[0].className.toLowerCase().indexOf("loading") >= 0) {
                // if document hasn't loaded, wait for it and try again
                setTimeout(mitigateDuplicateCss, 200);
            } else {
                const headLinks = Array.from(document.head.querySelectorAll("link")).filter(
                    (value) => value.href && value.href.indexOf(".css") >= 0
                );
                const linksToRemove = headLinks.filter((link, index) => index !== 0);
                linksToRemove.forEach((link) => {
                    document.head.removeChild(link);
                });
            }
        }
    }
}

function appendStylesheetToDocument(styles) {
    if (isBrowser()) {
        const styleTag = document.createElement("style");

        if (styleTag.styleSheet) {
            styleTag.styleSheet.cssText = styles;
        } else {
            styleTag.appendChild(document.createTextNode(styles));
        }

        document.getElementsByTagName("head")[0].appendChild(styleTag);
    }
}

export default App;
