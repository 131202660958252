import React from "react";
import { FormSelect } from "./Input";

const thisYear = new Date().getUTCFullYear();

export function RecentYearSelector({ name, label, maxYears }) {
    const studyStart = Array(thisYear - (thisYear - maxYears))
        .fill("")
        .map((v, idx) => thisYear - idx);

    return (
        <FormSelect name={name} label={label}>
            {studyStart.map((year) => {
                return (
                    <option key={year} value={year}>
                        {year}
                    </option>
                );
            })}
        </FormSelect>
    );
}
export function ModalRecentYearSelector({ name, label, maxYears, onChange }) {
    const studyStart = Array(thisYear - (thisYear - maxYears))
        .fill("")
        .map((v, idx) => thisYear - idx);

    return (
        <FormSelect name={name} label={label} onChange={onChange()}>
            {studyStart.map((year) => {
                return (
                    <option key={year} value={year}>
                        {year}
                    </option>
                );
            })}
        </FormSelect>
    );
}
