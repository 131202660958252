import { GoCheck } from "react-icons/go";
import { Icon, Trans, useTrans, VOUCHER_USE_TYPE } from "base";
import { motion } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { VoucherNumber } from "./VoucherNumber";

function VoucherCode({ info, useType, loadRedeemInfo, wrongPin }) {
    const code = info && info.code;
    const qrCode = info && info.qrCode;
    const url = info && info.url;
    const redeemDate = info && info.created;
    const copyText = useTrans("voucher.code_copied");

    const pinRef = useRef("");
    const [pinError, setPinError] = useState(wrongPin ? "voucher.pin.invalid" : "");
    const [pinLoading, setPinLoading] = useState(false);
    // render
    if (useType === VOUCHER_USE_TYPE.MOBILE_PIN) {
        return (
            <>
                {info ? <MobilePinInfo voucherNumber={info.voucherNumber} redeemDateString={redeemDate} /> : <MobilePin />}
                {qrCode && <QRCode />}
            </>
        );
    } else if (!info) {
        return null;
    } else {
        return (
            <>
                {code && <Code />}
                {qrCode && <QRCode />}
                {!url && !qrCode && !code && <VoucherNumber info={info} />}
            </>
        );
    }

    function Code(props) {
        return (
            <>
                <p style={{ marginBottom: "0.75rem" }}>
                    <Trans id="voucher.your_code" />
                </p>
                <div className="field has-addons is-full-width">
                    <div className="control is-flex-stretch">
                        <input className="input" readOnly value={code} id="voucher_code_input_field" onClick={selectAll} />
                    </div>
                    <div className="control is-flex">
                        <button className="button has-background-light" onClick={copyToClipboard} disabled={!info}>
                            <Trans id={"default.copy"} />
                        </button>
                    </div>
                </div>
            </>
        );
    }

    function QRCode(props) {
        return (
            <div className="my-5 qr-code">
                <img src={qrCode} />
            </div>
        );
    }

    function MobilePin(props) {
        return (
            <>
                <div className="field has-addons is-justify-content-center mt-2 mb-5">
                    <div className="control">
                        <input
                            className="input is-size-5 is-tracked-mega"
                            type="number"
                            name="pin"
                            ref={pinRef}
                            placeholder="1234"
                            style={{ width: "7em" }}
                        />
                    </div>
                    <div className="control is-flex">
                        <button className="button is-medium is-primary" onClick={sendPin} disabled={pinLoading}>
                            <Trans id="voucher.pin.submit" />
                        </button>
                    </div>
                </div>
                {pinError && (
                    <div className="help is-danger mb-4">
                        <Trans id={pinError} />
                    </div>
                )}
                <p className="notification mb-4">
                    <Trans id="voucher.pin.info" containsHTML />
                </p>
            </>
        );
    }

    function MobilePinInfo({ voucherNumber, redeemDateString }) {
        const date = redeemDateString ? new Date(redeemDate).toLocaleString() : "";
        return (
            <>
                <h3 className="title is-5 has-text-success">
                    <motion.div
                        initial={{ scale: 2.0 }}
                        animate={{ scale: 1.0 }}
                        transition={{
                            delay: 0.05,
                            duration: 0.5,
                            ease: "backInOut",
                        }}
                    >
                        <Icon icon={GoCheck} className="has-text-success" size="2rem" />
                    </motion.div>
                    <Trans id="voucher.pin.success" />
                </h3>
                {date && (
                    <h4 className="subtitle is-7 has-text-grey mb-6">
                        <Trans id="voucher.pin.redeemDate" />
                        {date}
                        <br />
                        {voucherNumber}
                    </h4>
                )}
            </>
        );
    }

    function selectAll() {
        const input = document.getElementById("voucher_code_input_field");
        input.focus();
        input.setSelectionRange(0, input.value.length);
    }

    function copyToClipboard() {
        selectAll();
        try {
            document.execCommand("copy");
            toast.success(copyText, {
                position: "top-right",
            });
        } catch (error) {
            console.error(error);
        }
    }

    function sendPin() {
        const pin = pinRef.current.value;
        if (pin === "") {
            setPinError("voucher.pin.invalid");
            return;
        } else {
            setPinLoading(true);
            setPinError("");

            loadRedeemInfo(pin).catch((error) => {
                setPinError("voucher.pin.invalid");
                setPinLoading(false);
            });
        }
    }
}

export default VoucherCode;
