import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import styled, { keyframes } from "styled-components";

export function NotificationBar({ isOverlay = true, children }) {
    return (
        <AnimationWrapper isOverlay={isOverlay}>
            <TopStyle isOverlay={isOverlay}>
                <Bar>{children}</Bar>
            </TopStyle>
        </AnimationWrapper>
    );
}

function AnimationWrapper({ isOverlay, children }) {
    if (isOverlay) {
        return children;
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ height: 0 }}
                animate={{ height: "100%" }}
                transition={{
                    type: "tween",
                    ease: "easeOut",
                    duration: 0.3,
                }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
}

const moveDown = keyframes`
    from {
        transform: scaleY(0.2);
    }
    to {
        transform: scaleY(1);
    }
`;

const TopStyle = styled.div`
    position: ${(props) => (props.isOverlay ? "fixed" : "relative")};
    top: 0;
    left: 0;
    right: 0;
    z-index: 30;
    animation: ${moveDown} 0.3s ease-out;
    transform-origin: 50% 0;
    opacity: 0.95;
    ${(props) => props.isOverlay && "overflow: hidden"};
`;

const Bar = styled.div.attrs((props) => ({
    className: "notification is-info is-marginless",
}))`
    height: 100%;
`;
