import { API } from "../core/api";

export function getUniversities(query, country, limit = 20) {
    const url = `organisation?page=1&limit=${limit}&query=${query}&country=${country}&sort=_score&order=desc&strict=false`;
    return API.request(url, "GET", "").then((response) => {
        return Promise.resolve(response.items);
    });
}
export function getUniversity(id) {
    const url = `organisation?id=${id}`;
    return API.request(url, "GET", "").then((response) => {
        return Promise.resolve(response);
    });
}
