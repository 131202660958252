import { API } from "../core/api";

export function validateEmail(email) {
    return API.request(
        "validate/email",
        "POST",
        "",
        JSON.stringify({
            email: email,
        })
    );
}

export function validateMatriculationNr(nr, organisationId) {
    return API.request(
        "validate/organisation/matriculation-number",
        "POST",
        "",
        JSON.stringify({
            organisation: organisationId,
            matriculation_number: nr,
        })
    );
}
