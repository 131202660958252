import React, { useContext } from "react";
import { GA } from "../core/google_analytics";
import { IntlContext } from "../core/IntlContext";
import { LocalStorage } from "../core/storage";
import { FlagIcon } from "./Icon";
import { Link } from "./Link";
import { Modal } from "./Modal";
import { Trans } from "./Trans";

export function CountrySelectorButton({ availableCountries, onClick, forceShow = false, className, textColor }) {
    if (!forceShow && (!availableCountries || availableCountries.length < 2)) {
        return null;
    }

    const Intl = useContext(IntlContext);
    const activeCountry = Intl.getCountry();

    return (
        <Link className={`${className && className + " "} is-flex is-align-items-center has-text-grey-dark country`} onClick={onClick}>
            <FlagIcon country={activeCountry} />
            <span style={{ color: textColor ?? "unset" }}>
                <Trans id="country.change" />
            </span>
        </Link>
    );
}

// position = 'top' | 'bottom'
export function CountrySelector({ visible, onClose, availableCountries, position = "" }) {
    const Intl = useContext(IntlContext);
    const activeCountry = Intl.getCountry();

    if (!visible) return null;

    return (
        <Modal isVisible={visible} onClose={onClose} maxWidth={"500px"} position={position}>
            <h2 className="title is-4">
                <Trans id="country.change" />
            </h2>
            <ul className="columns is-flex-wrap-wrap is-tablet">
                {availableCountries &&
                    availableCountries.sort().map((country) => {
                        if (country === activeCountry) {
                            return null;
                        } else {
                            return (
                                <li className="column is-half-mobile is-one-third-tablet" key={country}>
                                    <Link className="is-flex is-align-items-center color-unset" onClick={() => click(country)}>
                                        <FlagIcon country={country} className="mr-2" size="1.5rem" />
                                        <Trans id={"country." + country} />
                                    </Link>
                                </li>
                            );
                        }
                    })}
            </ul>
            <hr />
            <p className="is-flex is-align-items-center" style={{ opacity: 0.75 }}>
                <Trans id="country.current" />
                &nbsp;
                <FlagIcon country={activeCountry} size="1.5rem" />
                <b>
                    <Trans id={"country." + activeCountry} />
                </b>
            </p>
        </Modal>
    );

    function click(country) {
        Intl.setCountry(country, true, true);
        LocalStorage.set("country", country);
        LocalStorage.set("country_ip", country);
        GA.setAutoSendEvent("country", country);
        onClose();
    }
}
