import React from "react";
import { createContext } from "react";
import { useMatch } from "react-router-dom";
export const BaseUrlContext = createContext({});
function BaseUrlProvider({ children, domain }) {
  let baseUrl;
  if (domain == "verify") {
    const match = useMatch("/:country/:company/*");
    baseUrl = match.pathnameBase + "/";
  } else {
    baseUrl = "/"
  }
    return <BaseUrlContext.Provider value={baseUrl}>{children}</BaseUrlContext.Provider>;
}

export default BaseUrlProvider;