import { API } from "../core/api";

export function reverifyUser(data) {
    return API.request("user/reverification", "POST", API.getToken(), JSON.stringify(data));
}

export function loginToOldFrontend(token) {
    return API.request("me", "GET", token).then((response) => {
        let tld = response.country.tld;
        if (process.env.NODE_ENV !== "production") {
            tld = "dev";
        } else if (!tld || tld !== "at" || tld !== "de" || tld !== "ch") {
            tld = "at";
        }
        const url = `https://www.iamstudent.${tld}/me/login/api`;
        return fetch(url, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "X-Api-Token": token,
            },
        })
            .then((response) => {
                if (response.ok) return response.json();
            })
            .catch((error) => {
                console.log(error);
            });
    });
}
