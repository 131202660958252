import React from "react";
import { Trans } from "../Trans";

export function FormErrorList({ errors }) {
    const allErrors = getFormErrorKeys(errors);
    if (allErrors.length === 0) {
        return null;
    }

    return allErrors.map((errorKey, index) => {
        return (
            <span key={index} className="help is-danger">
                {allErrors.length > 1 && <span>- </span>}
                <Trans id={errorKey} />
            </span>
        );
    });
}

export function getFormErrorKeys(errors) {
    if (errors && errors.errors && errors.errors.children) {
        const allErrors = [];
        const children = errors.errors.children;
        for (const key in children) {
            if (!Object.prototype.hasOwnProperty.call(children, key)) continue;

            const prop = children[key];
            if (typeof prop === "object") {
                if (Object.prototype.hasOwnProperty.call(prop, "errors")) {
                    prop.errors.forEach((errorKey) => {
                        allErrors.push(errorKey);
                    });
                }
            }
        }
        return allErrors;
    } else if (errors?.status > 400) {
        const allErrors = [];
        allErrors.push(errors.key);
        return allErrors;
    }
    return [];
}
