import React from "react";
import { Trans } from "../Trans";

export function InAppBroserNotification({ inAppBrowser = false }) {
    if (!inAppBrowser) {
        return null;
    }
    return (
        <div className="notification is-info is-light mt-3 is-size-7">
            <Trans id={"register.form.hint.in_app_browser"} />
        </div>
    );
}
