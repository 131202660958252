import { API, apiParameterDefaults } from "../core/api";

export function getSearchSuggestions(country) {
    if (!country) {
        return null;
    }
    let params = {
        country: country,
    };

    return API.getRequest("search/trending", API.getToken(), params);
}
