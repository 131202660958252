import { API, LocalStorage } from "base";
import queryString from "query-string";
import React from "react";
import NotFound404 from "./NotFound404";
import { useLocation, useNavigate } from "react-router-dom";

function FacebookRedirect({ match }) {
    const location = useLocation();
    const history = useNavigate();
    // unsuccessful
    if (location.search) {
        const query = queryString.parse(location.search);
        if (!query || !query.state) {
            return <NotFound404 />;
        }

        const state = decodeToJson(query.state);
        if (state) {
            if (query.error) {
                if (state.parentUrl) {
                    saveState(state.path, "fb.error." + query.error);
                    window.location = state.parentUrl;
                } else {
                    history({
                        pathname: state.path,
                        state: {
                            errorKey: "fb.error." + query.error,
                        },
                    });
                }
                return null;
            }
        }
    }

    // successful login
    if (location.hash) {
        const parsedHash = queryString.parse(location.hash);
        const state = decodeToJson(parsedHash.state);
        const token = parsedHash.access_token;

        let fbInfo;

        getUserInfo(token)
            .then((info) => {
                fbInfo = info;

                if (state.path.endsWith("register")) {
                    return API.request(
                        "validate/email",
                        "POST",
                        "",
                        JSON.stringify({
                            email: info.email,
                        })
                    );
                } else if (state.path.endsWith("login")) {
                    return API.request(
                        "oauth/facebook/check",
                        "POST",
                        "",
                        JSON.stringify({
                            token: token,
                        }),
                        ""
                    );
                }
            })
            .then((apiResponse) => {
                if (!fbInfo) {
                    return Promise.reject();
                }

                if (apiResponse.token) {
                    API.setToken(apiResponse.token);
                    API.setRefreshToken(apiResponse.refresh_token);
                }

                if (state.path.endsWith("register")) {
                    const path = state.path;
                    const mainPath = path.replace("/user", "");
                    const registerPath = mainPath + "/" + fbInfo.email + "?fb=true&token=" + token;
                    if (state.parentUrl) {
                        saveState(registerPath);
                        window.location = state.parentUrl;
                    } else {
                        history(registerPath);
                    }
                } else if (state.path.endsWith("login")) {
                    if (state.parentUrl) {
                        window.location = state.parentUrl;
                        saveState("");
                    } else {
                        history({
                            pathname: state.baseUrl,
                            state: {
                                shouldLogin: true,
                            },
                        });
                    }
                }
            })
            .catch((error) => {
                const errorKey = error.key ? "fb." + error.key : "fb.error.access_denied";
                if (state.parentUrl) {
                    saveState(state.path, errorKey);
                    window.location = state.parentUrl;
                } else {
                    history({
                        pathname: state.path,
                        state: {
                            errorKey: errorKey,
                        },
                    });
                }
            });
    }

    // render
    return (
        <div className="is-flex is-justify-content-center is-align-items-center has-text-centered" style={{ height: "100vh" }}>
            <div className="section is-measure-wide">
                <div className="is-size-1 loada is-loading-inline">
                    <span className="is-sr-only">Waiting...</span>
                </div>
            </div>
        </div>
    );
}

function getUserInfo(token) {
    const url = "https://graph.facebook.com/me?fields=name,email&access_token=" + token;
    let request = new XMLHttpRequest();

    return new Promise(function (resolve, reject) {
        request.onload = function () {
            if (request.status >= 200 && request.status < 300) {
                resolve(request.response);
            } else {
                reject(request.response);
            }
        };
        request.open("GET", url);
        request.responseType = "json";
        request.send();
    });
}

function decodeToJson(uri) {
    const state = decodeURIComponent(uri);
    if (state) {
        return JSON.parse(state);
    }
    return "";
}

function saveState(path, error = "") {
    if (LocalStorage.isAvailable()) {
        const storage = window.sessionStorage;
        const token = API.getToken();
        const refreshToken = API.getRefreshToken();
        if (path) {
            storage.setItem("path", path);
        }
        if (token) {
            storage.setItem("token", token);
        }
        if (refreshToken) {
            storage.setItem("refresh_token", API.getRefreshToken());
        }
        if (error) {
            storage.setItem("error", error);
        }
    }
}

export default FacebookRedirect;
