import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { IntlProviderWrapper } from "../../core/IntlContext";
import { UserContextWrapper } from "../../core/User";
import { TooManyRequestsInfo } from "../TooManyRequestsInfo";
import { AppRequirementsBar } from "./AppRequirementsBar";
import { UnsupportedBrowserOverlay } from "./UnsupportedBrowserOverlay";

export const IamBaseContext = React.createContext(null);

// Use this at the very outer layer of your application
// It will provide it's children with the following base functionality:
// 1) Language + Country: useContext(IntlContext)
// 2) User: useContext(UserContext)
// 3) Theme: automatically available in styled components via {props => props.theme}
// If you provide the data prop "full" the Usercontext get expanded with more Data from all API calls(atm only total of new Items)
export function IamBaseProvider({ theme, translations, supportUrl, children, data = "" }) {
    const [theTheme, setTheme] = useState(theme);
    const updaters = {
        setTheme: setTheme,
    };

    return (
        <IntlProviderWrapper translations={translations}>
            <ThemeProvider theme={theTheme}>
                <UserContextWrapper data={data}>
                    <>
                        <AppRequirementsBar />
                        <UnsupportedBrowserOverlay supportUrl={supportUrl}>
                            <IamBaseContext.Provider value={updaters}>{children}</IamBaseContext.Provider>
                        </UnsupportedBrowserOverlay>
                        <TooManyRequestsInfo />
                    </>
                </UserContextWrapper>
            </ThemeProvider>
        </IntlProviderWrapper>
    );
}
