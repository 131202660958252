import React, { useContext } from "react";
import { Navigate, useMatch, useNavigate } from "react-router-dom";
import { config, RegisterModal } from "../../../base";
import { UserContext } from "base";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "../../../base/core/auth";
import { routes } from "../../routes";

function UserRegistry({ loginPathOrFunc, registerFunc, googleLoginFunc, fbLoginFunc }) {
    const User = useContext(UserContext);
    const match = useMatch("/:country/:organisation/*");
    const baseUrl = match.pathnameBase;
    const history = useNavigate();
    return (
        <>
            {User.inVerify ? (
                <Navigate to={baseUrl} replace={true} />
            ) : (
                <GoogleOAuthProvider clientId={config("google_client_id")}>
                    <AuthProvider
                        baseUrl={baseUrl}
                        thanksUrl={baseUrl}
                        loginPathOrFunc={routes.userLogin}
                        onClose={() => {
                            history(baseUrl);
                        }}
                    >
                        <RegisterModal />
                    </AuthProvider>
                </GoogleOAuthProvider>
            )}
        </>
    );
}

export default UserRegistry;
