import { FaRegCheckCircle } from "react-icons/fa";
import { FaUniversity } from "react-icons/fa";
import { IamStudentLogo, Icon, Trans } from "base";
import React from "react";

export function WhatIsIamStudent({theme}) {
    return (
        <div className="is-size-7" style={{backgroundColor:"transparent", color: theme && (theme.color.text ?? "unset")}}>
            <h3 className="is-size-6 has-text-weight-bold is-flex is-justify-content-center">
                <span className="mr-1">
                    <Trans id="whatisiamstudent.title" />
                </span>
                <IamStudentLogo />?
            </h3>
            <p className="mb-2 mt-2">
                <Trans id={"whatisiamstudent.text"} />
            </p>
            <ul className="mb-2">
                <li className="is-flex is-align-items-center">
                    <Icon
                        icon={FaRegCheckCircle}
                        className="has-text-success mr-1"
                    />
                    <span>
                        <Trans id={"whatisiamstudent.check1"} />
                    </span>
                </li>
                <li className="is-flex is-align-items-center">
                    <Icon
                        icon={FaRegCheckCircle}
                        className="has-text-success mr-1"
                    />
                    <Trans id={"whatisiamstudent.check2"} />
                </li>
            </ul>
            <div className="columns is-mobile">
                <div className="column is-flex is-align-items-center">
                    <Icon
                        icon={FaUniversity}
                        className="has-text-darkblue mr-1"
                    />
                    <span>
                        <Trans id={"whatisiamstudent.universitycount"} />
                    </span>
                </div>
                <div className="column is-flex is-align-items-center">
                    <Icon
                        icon={FaUniversity}
                        className="has-text-darkblue mr-1"
                    />
                    <span>
                        <Trans id={"whatisiamstudent.studentcount"} />
                    </span>
                </div>
            </div>
        </div>
    );
}
