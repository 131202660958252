import { AnimatePresence, motion } from "framer-motion";
import React from "react";

export function PageAnimation({ pageIndex, children }) {
    return (
        <AnimatePresence initial={false}>
            <motion.div
                key={pageIndex}
                initial="initial"
                animate="in"
                exit="out"
                variants={{
                    initial: {
                        x: "100vw",
                        scale: 1,
                        opacity: 0.0,
                        position: "absolute",
                    },
                    in: {
                        x: 0,
                        scale: 1,
                        opacity: 1.0,
                        position: "static",
                    },
                    out: {
                        x: "-100vw",
                        scale: 1.2,
                        opacity: 0.0,
                        position: "absolute",
                    },
                }}
                transition={{
                    type: "tween",
                    ease: "anticipate",
                    duration: 0.7,
                }}
                style={{
                    transformOrigin: "top",
                }}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
}
