import React from "react";
import { Link as RouterLink, NavLink as RouterNavLink } from "react-router-dom";
import styled, { css } from "styled-components";

export function Link({ to, openNewTab, onClick, className, children, as }) {
    const url = to && to.pathname ? to.pathname : to;

    if (url && isExternal(url)) {
        const target = openNewTab === false ? "_self" : "_blank";
        return (
            <StyledPlainLink href={url} onClick={onClick} className={className} target={target} as={as}>
                {children}
            </StyledPlainLink>
        );
    } else if (to) {
        const target = openNewTab ? "_blank" : "_self";
        return (
            <StyledLink to={to} target={target} className={className} onClick={onClick}>
                {children}
            </StyledLink>
        );
    } else {
        return (
            <StyledPlainLink onClick={onClick} className={className}>
                {children}
            </StyledPlainLink>
        );
    }
}

export function NavLink({ to, className, elementStyle, children }) {
    return (
        <StyledNavLink to={to} className={className} style={elementStyle || null}>
            {children}
        </StyledNavLink>
    );
}

const style = css`
    &,
    &:visited {
        background-color: transparent;
        color: ${(props) => props.theme.color.primary};
        text-decoration-color: ${(props) => props.theme.color.primary};
        text-decoration: none;
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`;

function isExternal(url) {
    return /^https?:\/\//.test(url);
}

const StyledLink = styled(RouterLink)([style]);
const StyledNavLink = styled(RouterNavLink)([style]);
const StyledPlainLink = styled.a([style]);
