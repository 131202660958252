import { API, apiParameterDefaults } from "../core/api";

export function getRecommendationsByUser(country, options = {}) {
    if (!country) {
        return null;
    }
    let params = {
        country: country,
        limit: apiParameterDefaults?.limit,
        ...options,
    };

    return API.getRequest("search/recommendation", API.getToken(), params);
}
