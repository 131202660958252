/*
    How to use:
    <UserContextWrapper>
        <YourApp />
    </UserContextWrapper>

    This will give you access to the UserContext:
    const user = useContext(UserContext);
    user.login('admin', 'password');
*/

import React, { useContext, useState } from "react";
import { loginToOldFrontend } from "../api/user";
import { API } from "./api";
import { buildUserDomain } from "./helper";
import { IntlContext } from "./IntlContext";
import { newTotalDummy } from "./dummies";

export const UserVerificationState = {
    NULL: 0,
    PENDING: 1,
    NEEDS_UPLOAD: 2,
    HAS_UPLOAD: 3,
    NEEDS_ACTIVE_VERIFICATION: 4,
    DECLINED: 6,
    EXPIRED: 7,
    LOCKED: 8,
    VERIFIED: 9,
    INACTIVE: 10,
};

export const UserContext = React.createContext({});

export function UserContextWrapper({ children, data }) {
    const [user, setUser] = useState({
        isLoggedIn: null,
        isLoggingIn: false,
        verificationStatus: UserVerificationState.NULL,
        verificationError: null,
        email: "",
        emailOther: "",
        total: null,
        inVerify: false,
        hasVerificationUpload: "",
    });
    const intl = useContext(IntlContext);
    const country = intl.country;
    async function setStateAfterVerify(token) {
        await API.request("me/status", "GET", token)
            .then((response) => {
                const state = _computeVerificationState(response);
                setUser((prev) => ({
                    ...prev,
                    isLoggedIn: true,
                    isLoggingIn: false,
                    email: response.email,
                    emailOther: response.email_other,
                    verificationStatus: state,
                    verificationError: null,
                    hasVerificationUpload: response.hasVerificationUpload,
                }));
                if (data == "full") {
                    //fetchNewItemsTotal();
                    //Api Dummy for New Total Item Count
                    //uncommit line underneath to use dummy object
                    setUser((prev) => ({
                        ...prev,
                        total: newTotalDummy.total,
                    }));
                }
                if (response?.userStatus !== "verified") {
                    pollVerificationStatus();
                }
            })
            .catch((error) => {
                // User is inactive
                setUser((prev) => ({
                    ...prev,
                    isLoggedIn: true,
                    isLoggingIn: false,
                    verificationStatus: error.status === 401 ? UserVerificationState.INACTIVE : UserVerificationState.NULL,
                    verificationError: error.key,
                }));
            });
    }
    return (
        <UserContext.Provider
            value={{
                // state
                isLoggedIn: user.isLoggedIn,
                isLoggingIn: user.isLoggingIn,
                verificationStatus: user.verificationStatus,
                verificationError: user.verificationError,
                email: user.email,
                emailOther: user.emailOther,
                total: user.total,
                inVerify: user.inVerify,
                hasVerificationUpload: user.hasVerificationUpload,
                // functions
                login,
                logout,
                autologinWithToken,
                pollVerificationStatus,
                hasToken,
                setLoggedIn,
                setInVerify,
            }}
        >
            {children}
        </UserContext.Provider>
    );
    function login(response) {
        API.setToken(response.token);
        API.setRefreshToken(response.refresh_token);
        setStateAfterVerify(response.token);
        loginToOldFrontend(response.token);
    }

    function hasToken() {
        return API.getToken() && API.getToken().length > 0;
    }

    function logout() {
        API.clearTokens();
        setUser((prev) => ({
            ...prev,
            isLoggedIn: false,
            isLoggingIn: false,
            verificationStatus: UserVerificationState.NULL,
            verificationError: null,
            email: "",
            emailOther: "",
            setFirstname: "",
        }));
    }

    function autologinWithToken() {
        const token = API.getToken();
        const refreshToken = API.getRefreshToken();
        if ((token && API.hasTokenExpired(token)) || (!token && refreshToken)) {
            setLoggingIn(true);
            return API.refreshTokens()
                .then(() => {
                    setStateAfterVerify(token ?? refreshToken);
                    return Promise.resolve();
                })
                .catch((error) => {
                    setUser((prev) => ({ ...prev, isLoggingIn: false, isLoggedIn: false }));
                    return Promise.reject();
                });
        } else if (token) {
            setStateAfterVerify(token);
            return Promise.resolve();
        }
        setUser((prev) => ({ ...prev, isLoggingIn: false, isLoggedIn: false }));
        return Promise.reject();
    }

    function pollVerificationStatus(interval = 5000) {
        const token = API.getToken();
        if (token.length) {
            API.request("me/status", "GET", token)
                .then((response) => {
                    const state = _computeVerificationState(response);
                    setUser((prev) => ({
                        ...prev,
                        verificationStatus: state,
                        verificationError: null,
                        email: response.email,
                        emailOther: response.emailOther,
                    }));
                    if (response?.userStatus !== "verified") {
                        if (interval > 0) {
                            setTimeout(pollVerificationStatus, interval);
                        }
                    }
                })
                .catch((error) => {
                    // User is inactive
                    setUser((prev) => ({
                        ...prev,
                        verificationStatus: error.status === 401 ? UserVerificationState.INACTIVE : UserVerificationState.NULL,
                        verificationError: error.key,
                    }));
                    if (interval > 0) {
                        setTimeout(pollVerificationStatus, interval);
                    }
                });
        }
    }
    function fetchNewItemsTotal(page = 1) {
        const token = API.getToken();
        if (token) {
            API.request(`me/new?page=${page}&country=${country}`, "GET", token).then((response) => {
                if (response) {
                    setUser((prev) => ({
                        ...prev,
                        total: response.total,
                    }));
                }
            });
        }
    }
    function setInVerify(value) {
        setUser((prev) => ({
            ...prev,
            inVerify: value,
        }));
    }
    function setLoggingIn(value) {
        setUser((prev) => ({
            ...prev,
            isLoggingIn: value,
        }));
    }
    function setLoggedIn(params) {
        console.log(params);
        setUser((prev) => ({
            ...prev,
            isLoggedIn: params,
        }));
    }
    // we can only be in 1 state at a time
    function _computeVerificationState(statusResponse) {
        const { userStatus, needsActivateVerification, needsVerificationUpload, hasVerificationUpload } = statusResponse;

        if (user.verificationError === "error.userlocked") {
            return UserVerificationState.LOCKED;
        }

        if (userStatus === undefined) {
            return UserVerificationState.NULL;
        }

        if (userStatus === "verified") {
            return UserVerificationState.VERIFIED;
        }

        if (needsVerificationUpload) {
            if (hasVerificationUpload) {
                return UserVerificationState.HAS_UPLOAD;
            }
            return UserVerificationState.NEEDS_UPLOAD;
        }

        if (needsActivateVerification || userStatus === "needs_confirmation") {
            return UserVerificationState.NEEDS_ACTIVE_VERIFICATION;
        }

        if (userStatus === "declined") {
            return UserVerificationState.DECLINED;
        }

        if (userStatus === "expired") {
            return UserVerificationState.EXPIRED;
        }

        // everything we don't know of will be shown as pending
        return UserVerificationState.PENDING;
    }
}
// export const useUser = () => {
//     return useContext(UserContext)
// }
