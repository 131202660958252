import { API } from "../core/api";

export function getConfigFeature(country, feature) {
    if (!country || !feature) {
        return null;
    }
    let params = {
        country: country,
    };

    return API.getRequest("config/feature/" + feature, "", params);
}
