export function BaseTheme() {
    return {
        color: {
            primary: "#d41f39",
            primaryActive: "#ad1b2d",
            secondary: "#383d4e",
            secondaryActive: "#49586b",
            success: "#5bec5b",
            text: "#111",
            white: "#fff",
            background: "#fff",
            grayLight: "#efefef",
            grayDark: "#333333",
        },
        space: {
            $1: "4px",
            $2: "8px",
            $3: "16px",
        },
        breakpoint: {
            mobileOnly: "max-width: 480px",
            tabletMin: "min-width: 480px",
            tablet: "min-width: 769px",
            desktop: "min-width: 1024px",
            widescreen: "min-width: 1216px",
        },
    };
}
