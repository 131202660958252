import React, { useState } from "react";
import { FormTextInput, FormTextInputAutovalue } from "../../../components/form/Input";
import { Trans, useTrans } from "../../../components/Trans";

export function EmailOtherInput({ organisation, uniMailAutovalue, onUnimailToggle }) {
    const [isWritable, setWritable] = useState(false);
    const placeholder = useTrans("register.form.placeholder.email_other");

    if (!organisation) {
        return null;
    }

    if (organisation.syntax && organisation.disableMatriculationNumber === false) {
        return (
            <FormTextInputAutovalue
                name="email_other"
                label={<Trans id="register.form.label.email_other" />}
                placeholder={placeholder}
                autoValue={uniMailAutovalue}
                isWritable={isWritable}
                onToggleEditMode={toggleUnimailEdit}
                type="email"
            />
        );
    } else if (organisation.tld && (!organisation.syntax || organisation.disableMatriculationNumber === true)) {
        return <FormTextInput name="email_other" label={<Trans id="register.form.label.email_other" />} placeholder={placeholder} />;
    } else {
        return null;
    }

    function toggleUnimailEdit() {
        setWritable(!isWritable);
        if (onUnimailToggle) {
            onUnimailToggle(!isWritable);
        }
    }
}
