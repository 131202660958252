import { FaFacebookSquare } from "react-icons/fa";
import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { config } from "../core/config";
import { inIframe, isBrowser, isMobileDevice } from "../core/helper";
import { StyledFacebookButton } from "./Button";
import { Icon } from "./Icon";

export function FacebookButton({
    label = "Facebook",
    baseUrl = "/",
    onClick = () => {},
    onResponse = () => {},
    loading = false,
    autoLoad = false,
    ...props
}) {
    const id = config("fb_id");
    const redirectUrl = isBrowser() ? window.location.origin + "/at/fb-redirect" : "";
    const isMobile = isMobileDevice();
    const parentUrl = inIframe() && isBrowser() ? window.verifyParentUrl : "";

    const state = encodeURIComponent(
        JSON.stringify({
            path: isBrowser() ? window.location.pathname : "",
            baseUrl: baseUrl,
            parentUrl: parentUrl || "",
        })
    );

    function buildMobileUrl() {
        let url = "https://m.facebook.com/dialog/oauth?client_id=" + id;
        url += "&redirect_uri=" + redirectUrl;
        url += "&state=" + state;
        url += "&return_scopes=false&scope=public_profile%2Cemail&response_type=token&auth_type";
        return url;
    }

    return (
        <FacebookLogin
            appId={id}
            fields="name,email"
            autoLoad={autoLoad}
            authType={"rerequest"}
            callback={onResponse}
            onClick={() => onClick()}
            xfbml={true}
            status={true}
            cookie={true}
            isMobile={false}
            disableMobileRedirect={true}
            version="3.1"
            state={state}
            responseType="token"
            render={(renderProps) => {
                return (
                    <StyledFacebookButton
                        onClick={renderProps.onClick}
                        disabled={renderProps.isDisabled || loading}
                        loading={renderProps.isProcessing || loading}
                        {...props}
                    >
                        <Icon icon={FaFacebookSquare} className="has-text-white" />
                        <span>{label}</span>
                    </StyledFacebookButton>
                );
            }}
        />
    );
}
