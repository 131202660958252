import * as Sentry from "@sentry/react";
import { BaseTheme, configInit, IamBaseProvider } from "base";
import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.js";
import "./main.scss";

// Init .env variables -> restart local server to have immediate effect
configInit(process.env.API_URL, process.env.FB_APP_ID, process.env.GOOGLE_CLIENT_ID, null, process.env.APPLE_REDIRECT_URI);

Sentry.init({
    dsn: "https://54fd7a56c9c74d88bcc4abf71f32fde6@o430248.ingest.sentry.io/5378401",
    environment: process.env.NODE_ENV,
});

// -- PRODUCTION ONLY ------------------------------------------------------------------>
if (process.env.NODE_ENV === "production") {
    // remove all console.logs in production
    if (window.console) {
        window.console.log = function (...args) {
            if (!window.__logs) window.__logs = [];
            window.__logs.push(args);
        };
        window.console.error = window.console.log;
        window.console.warn = window.console.log;
    }
    // remove all console.logs in production
    if (window.console) {
        window.console.log = function (...args) {
            if (!window.__logs) window.__logs = [];
            window.__logs.push(args);
        };
        window.console.error = window.console.log;
        window.console.warn = window.console.log;
    }
}

const translations = {
    en: () => import("../assets/translations/en.json"),
    de: () => import("../assets/translations/de.json"),
    fr: () => import("../assets/translations/fr.json"),
    nl: () => import("../assets/translations/nl.json"),
    it: () => import("../assets/translations/it.json"),
    cs: () => import("../assets/translations/cs.json"),
    da: () => import("../assets/translations/da.json"),
    fi: () => import("../assets/translations/fi.json"),
    pl: () => import("../assets/translations/pl.json"),
    sv: () => import("../assets/translations/sv.json"),
    es: () => import("../assets/translations/es.json"),
    "pt-PT": () => import("../assets/translations/pt-PT.json"),
};

const renderTarget = document.getElementById("App");
const root = createRoot(renderTarget);
if (renderTarget) {
    root.render(
        <Suspense>
            <IamBaseProvider translations={translations} theme={BaseTheme()}>
                <App />
            </IamBaseProvider>
        </Suspense>
    );
} else {
    console.warn("Could not find a render target for React.\nDid you forget to put a div with id='App' inside your markup?");
    console.warn("Could not find a render target for React.\nDid you forget to put a div with id='App' inside your markup?");
}

if (module.hot) {
    module.hot.accept();
}

document.addEventListener("DOMContentLoaded", (event) => {
    const app = document.getElementById("App");
    let prevHeight = 0;

    function updateHeight() {
        let height = Math.max(app.scrollHeight, app.offsetHeight);

        if (prevHeight !== height) {
            window.parent.postMessage(
                {
                    h: height,
                    id: "iam-verify-iframe",
                },
                "*"
            );

            // Fallback for old verify version (connect)
            window.parent.postMessage(
                {
                    h: height,
                    id: "iam-connect-iframe",
                },
                "*"
            );

            prevHeight = height;
        }
    }

    window.addEventListener("DOMContentLoaded load resize scroll", updateHeight);
    window.setInterval(updateHeight, 1000);
    window.updateHeight = updateHeight;
});

window.addEventListener("message", function (event) {
    if (event.data && event.data.id === "iam-verify") {
        console.log("event data:", event.data);
        if (event.data.parentUrl) {
            window.verifyParentUrl = event.data.parentUrl;
        }
    }
});
