import React from "react";
import styled from "styled-components";
import { IamStudentLogo } from "./IamStudentLogo";
import { inIframe } from "../core";

export function HeroTitle({ title = "", subtitle = null, isFixed = false }) {
    return (
        <Header className={isFixed ? "navbar is-fixed-top" : ""}>
            <div className="hero-body">
                <div className="is-flex is-justify-content-center is-relative">
                    {inIframe() && (
                        <>
                            <div className={"is-absolute is-left-corner"}>
                                <IamStudentLogo className="has-text-left-desktop has-text-center-touch "/>
                            </div>
                        </>
                    )}

                    <div>
                        <div className="container has-text-centered">
                            <h1 className="title is-4">{title}</h1>
                            {subtitle && <h2 className="subtitle is-6">{subtitle}</h2>}
                        </div>
                    </div>
                </div>
            </div>
        </Header>
    );
}

// not sure why we can't use props for background:
// ${(props) => props.theme.color.grayLight}
const Header = styled.header.attrs((props) => ({
    className: "hero is-small",
}))`
    background: #efefef;
    color: ${(props) => props.theme.color.text};
`;
