import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { GoCheck } from "react-icons/go";
import { Formik } from "formik";
import React from "react";
import { Button, PrimaryButton } from "../Button";
import { Icon } from "../Icon";
import { PageAnimation } from "../PageAnimation";
import { Trans } from "../Trans";

export const WizardContext = React.createContext({
    previous: null,
    next: null,
});

export class Wizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            values: props.initialValues,
        };
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.validate = this.validate.bind(this);
        this.validationSchema = this.validationSchema.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeCallback = this.onChangeCallback.bind(this);
    }

    next(values) {
        this.setState(
            (state) => ({
                page: Math.min(state.page + 1, this.props.children.length - 1),
                values: values,
            }),
            () => this.onChangeCallback("next")
        );
    }

    previous() {
        const history = this.props.history;
        if (this.state.page === 0 && history) {
            history(-1);
            return;
        }
        this.setState(
            (state) => ({
                page: Math.max(state.page - 1, 0),
            }),
            () => this.onChangeCallback("prev")
        );
    }

    onChangeCallback(nextOrPrev) {
        if (this.props.onChange && typeof this.props.onChange === "function") {
            const page = this.state.page;
            this.props.onChange(page, this.state.values, nextOrPrev);
        }
    }

    validate(values) {
        const activePage = React.Children.toArray(this.props.children)[this.state.page];
        return activePage.props.validate ? activePage.props.validate(values) : {};
    }

    validationSchema() {
        const activePage = React.Children.toArray(this.props.children)[this.state.page];
        return activePage.props.validationSchema ? activePage.props.validationSchema(this.state.values) : {};
    }

    handleSubmit(values, bag) {
        const { children, onSubmit } = this.props;
        const { page } = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values, bag);
        } else {
            bag.setTouched({});
            bag.setSubmitting(false);
            this.next(values);
        }
    }

    render() {
        const { children, history } = this.props;
        const { page, prevPage, values } = this.state;
        const activePage = React.Children.toArray(children)[page];
        const isLastPage = page === React.Children.count(children) - 1;

        const wizardProvider = {
            next: this.next,
            previous: this.previous,
        };

        return (
            <Formik
                initialValues={values}
                enableReinitialize={false}
                validate={this.validate}
                validationSchema={this.validationSchema}
                validateOnChange={false}
                validateOnBlur={true}
                onSubmit={this.handleSubmit}
            >
                {({ values, handleSubmit, isSubmitting, isValidating, isValid, dirty }) => (
                    <WizardContext.Provider value={wizardProvider}>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="mb-4 is-relative" style={{ zIndex: 1 }}>
                                <PageAnimation pageIndex={page}>{activePage}</PageAnimation>
                            </div>
                            <div className="field is-grouped is-grouped-right columns is-gapless">
                                <div className="control column">
                                    {(page > 0 || history) && (
                                        <Button type="button" onClick={this.previous} disabled={isValidating || isSubmitting}>
                                            <Icon icon={FaChevronLeft} className="mr-0" />
                                            &nbsp;&nbsp;
                                            <Trans id={"wizard.back"} />
                                        </Button>
                                    )}
                                </div>
                                <div className="control column">
                                    {!isLastPage && (
                                        <PrimaryButton
                                            type="submit"
                                            disabled={isValidating || isSubmitting || !isValid || !dirty}
                                            loading={isSubmitting}
                                        >
                                            <Trans id={"wizard.next"} />
                                            &nbsp;&nbsp;
                                            <Icon icon={FaChevronRight} className="ml-0" />
                                        </PrimaryButton>
                                    )}
                                    {isLastPage && (
                                        <PrimaryButton
                                            type="submit"
                                            disabled={isSubmitting || isSubmitting || !isValid}
                                            loading={isSubmitting}
                                        >
                                            <Trans id={"wizard.finish"} />
                                            &nbsp;&nbsp;
                                            <Icon icon={GoCheck} className="ml-0" />
                                        </PrimaryButton>
                                    )}
                                </div>
                            </div>
                        </form>
                    </WizardContext.Provider>
                )}
            </Formik>
        );
    }
}

Wizard.Page = ({ children }) => children;
