import React from "react";
import styled from "styled-components";

export function Divider({ content }) {
    return <DividerComponent>{content && <DividerContent>{content}</DividerContent>}</DividerComponent>;
}

const DividerComponent = styled.div`
    display: block;
    position: relative;
    border-top: 1px solid #dbdbdb;
    height: 1px;
    margin: 2rem 0;
    text-align: center;
`;

const DividerContent = styled.span`
    background: #fff;
    color: #b5b5b5;
    display: inline-block;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    transform: translateY(-1.1rem);
    text-align: center;
`;
