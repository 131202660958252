import { FaUpload } from "react-icons/fa";
import { GoCheck } from "react-icons/go";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { Icon } from "../Icon";
import { Link } from "../Link";
import { Trans } from "../Trans";

export function FormTextInput({
    label,
    onChange,
    isLoading,
    type = "text",
    labelClass,
    inputClass,
    customError = "",
    isSuccess = false,
    ...props
}) {
    const [field, meta] = useField(props);
    const id = props.id || props.name;
    const controlClass = isLoading
        ? "control is-fullwidth has-icons-right is-loading"
        : "control is-fullwidth has-icons-right";
    const theLabelClass = labelClass ? "label " + labelClass : "label";
    const theInputClass = inputClass ? "input " + inputClass : "input";

    function handleChange(e) {
        if (onChange) {
            onChange(e);
        }
        field.onChange(e);
    }

    return (
        <div className="field">
            <label className={theLabelClass} htmlFor={id}>
                {label}
            </label>
            <div className={controlClass}>
                <input
                    className={theInputClass}
                    id={id}
                    type={type}
                    {...field}
                    {...props}
                    onChange={handleChange}
                />
                {isSuccess && (
                    <Icon
                        icon={GoCheck}
                        className="is-small is-right has-text-success"
                    />
                )}
            </div>
            <FormHelpError meta={meta} />
            <FormCustomHelpError error={customError} />
        </div>
    );
}

export function FormTextInputAutovalue({
    label,
    type = "text",
    autoValue,
    isWritable,
    onToggleEditMode,
    ...props
}) {
    const id = props.id || props.name;
    const [field, meta, helpers] = useField(props);

    function edit(e) {
        onToggleEditMode();
        helpers.setValue(autoValue);
        const input = document.getElementById(id);
        input.focus();
    }

    const theValue = isWritable ? field.value : autoValue;
    const theField = isWritable ? field : null;

    return (
        <div className="field">
            <label className="label" htmlFor={id}>
                {label}
                <span className="has-text-weight-normal is-size-7">
                    &nbsp;&nbsp;
                    <Link onClick={edit}>
                        {isWritable ? (
                            <Trans id={"default.reset"} />
                        ) : (
                            <Trans id={"default.change"} />
                        )}
                    </Link>
                </span>
            </label>

            <div className="control is-fullwidth">
                <input
                    className="input"
                    id={id}
                    type={type}
                    {...theField}
                    {...props}
                    value={theValue}
                    disabled={!isWritable}
                />
            </div>

            {isWritable && <FormHelpError meta={meta} />}
        </div>
    );
}

export function FormCheckbox({ children, ...props }) {
    const [field, meta] = useField(props);
    return (
        <div className="field">
            <label className="checkbox is-fullwidth is-flex is-flex-direction-row">
                <input
                    className="mr-2 mt-1"
                    type="checkbox"
                    {...field}
                    {...props}
                    checked={field.value}
                />
                <span>{children}</span>
            </label>
            <FormHelpError meta={meta} />
        </div>
    );
}

export function FormRadiogroup({
    question,
    answers,
    values,
    onChange,
    ...props
}) {
    const [field, meta, helpers] = useField(props);
    const id = props.id || props.name;

    return (
        <div className="field">
            <label className="label" htmlFor={id}>
                {question}
            </label>
            <div className="control" role="group" aria-labelledby={id}>
                {answers.map((answer, index) => {
                    return (
                        <label className="radio is-block ml-4 py-1" key={index}>
                            <input
                                name={id}
                                type="radio"
                                className="mr-1"
                                value={values[index]}
                                onClick={(e) => {
                                    helpers.setValue(e.target.value);
                                    if (onChange) onChange(e.target.value);
                                }}
                                {...props}
                            />
                            {answer}
                        </label>
                    );
                })}
            </div>
            <FormHelpError meta={meta} />
        </div>
    );
}

export function FormSelect({ label, ...props }) {
    const [field, meta] = useField(props);
    const id = props.id || props.name;
    return (
        <div className="field">
            <label className="label" htmlFor={id}>
                {label}
            </label>
            <div className="control select is-fullwidth">
                <select id={id} {...field} {...props} />
            </div>
            <FormHelpError meta={meta} />
        </div>
    );
}

export function FormTextarea({ label, ...props }) {
    const [field, meta] = useField(props);
    const id = props.id || props.name;
    return (
        <div className="field">
            <label className="label" htmlFor={id}>
                {label}
            </label>
            <div className="control is-fullwidth">
                <textarea id={id} className="textarea" {...field} {...props} />
            </div>
            <FormHelpError meta={meta} />
        </div>
    );
}

export function FormFile({
    label,
    emptyState = "Keine Datei ausgewählt",
    onFileChange,
    overwriteFile,
    ...props
}) {
    const [field, meta, helpers] = useField(props);
    const ref = useRef(null);
    const formikContext = useFormikContext();
    const files = ref && ref.current && ref.current.files;
    const file = files && files.length > 0 && files[0];

    useEffect(() => {
        if (file) {
            onFileChange(file);
            setTimeout(() => {
                formikContext.validateForm();
            }, 0);
        }
    }, [file]);

    const theFile = overwriteFile ? overwriteFile : file;

    return (
        <div className="field">
            <label className="label" htmlFor={props.id || props.name}>
                {label}
            </label>
            <div className="file has-name is-fullwidth">
                <label className="file-label">
                    <input
                        ref={ref}
                        className="file-input"
                        type="file"
                        {...field}
                        {...props}
                    />
                    <span className="file-cta">
                        <Icon icon={FaUpload} className="mr-1" />
                        <span className="file-label">Auswählen…</span>
                    </span>
                    <span className="file-name">
                        {theFile && theFile.name}
                        {!theFile && <span>{emptyState}</span>}
                    </span>
                </label>
            </div>
            {meta.touched && meta.error && (
                <FormCustomHelpError error={<Trans id={meta.error} />} />
            )}
        </div>
    );
}

export function FormHelpError({ meta }) {
    if (meta.touched && meta.error) {
        return <div className="help is-danger">{meta.error}</div>;
    }
    return null;
}

function FormCustomHelpError({ error }) {
    if (error) {
        return <div className="help is-danger">{error}</div>;
    }
    return null;
}
