import React from "react";

export * from "./Registration/Registration/exports";

export const Registration = React.lazy(() => import("./Registration/Registration/Registration"));
export const OrganizationRequest = React.lazy(() => import("./Registration/OrganisationRequest"));
export const RegistrationThanks = React.lazy(() => import("./Registration/RegistrationThanks"));
export const RegistrationActivation = React.lazy(() => import("./Registration/RegistrationActivation"));
export const RegistrationVerification = React.lazy(() => import("./Registration/RegistrationVerification"));
export const ChangePassword = React.lazy(() => import("./Registration/ChangePassword"));
export const RequestNewPassword = React.lazy(() => import("./RequestNewPassword"));
export const ResendActivationLink = React.lazy(() => import("./ResendActivationLink"));
export const VerificationStatusContent = React.lazy(() => import("./VerificationStatusContent"));
export const OAuthConfirmScreen = React.lazy(() => import("./OAuth/OAuthConfirmScreen"));
export const Support = React.lazy(() => import("./Support"));
