let custom = null;

const shared = {
    platform: "iamstudent",
    platform_url: process.env.NODE_ENV === "production" ? "https://www.iamstudent.at" : "https://www.iamstudent.dev",
    platform_url_de: process.env.NODE_ENV === "production" ? "https://www.iamstudent.de" : "https://www.iamstudent.dev",
    platform_url_placeholder: process.env.NODE_ENV === "production" ? "https://www.iamstudent.{tld}" : "https://www.iamstudent.dev",
    international_platform_url: process.env.NODE_ENV === "production" ? "https://www.iamstudent.com" : "https://www.iamstudent.dev:5555",
    verify_url: "https://verify.iamstudent.com",
};

export function config(key) {
    if (custom) {
        return custom[key] || "";
    } else {
        return shared[key] || "";
    }
}

export function configInit(api, fb_id, google_client_id, gumlet_url, apple_redirect) {
    custom = {
        ...shared,
        ...{
            api: api,
            fb_id: fb_id,
            google_client_id: google_client_id,
            gumlet_url: gumlet_url,
            apple_redirect_uri: apple_redirect,
        },
    };
}
