import { Form, Formik } from "formik";
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { config } from "../core/config";
import { loginSchema } from "../views/Registration/Registration/validation";
import { PrimaryButton } from "./Button";
import { Divider } from "./Divider";
import { FacebookButton } from "./FacebookButton";
import { FormTextInput } from "./form/Input";
import { Link, NavLink } from "./Link";
import { Modal, ModalHeaderNotification } from "./Modal";
import { InAppBroserNotification } from "./notification/InAppBrowserNotification";
import { Trans, useTrans } from "./Trans";
import { GoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { authContext } from "../core/auth";

export function LoginModal() {
    const [loginError, setLoginError] = useState({ error: null, provider: null });
    const [oauthLoading, setOauthLoading] = useState(false);
    const [width, setWidth] = useState(0);
    const location = useLocation();
    const ref = useRef(null);
    const auth = useContext(authContext);
    const {
        oauth,
        login,
        error,
        loading,
        authProviders,
        appleRedirectUrl,
        registerPathOrFunc,
        googleAutoload,
        fbAutoload,
        inAppBrowser,
        initEmail,
        close,
        baseUrl,
        locale,
        appleAutoload,
        parsed,
    } = auth;
    // look if we have an error from the facebook redirect
    useLayoutEffect(() => {
        setWidth(ref.current.offsetWidth);
    });
    useEffect(() => {
        if (error.error) {
            setLoginError(error);
        }
    }, [error]);
    return (
        <Modal onClose={close ?? null} maxWidth={460} isVisible={true}>
            {location.state && location.state.loginError && (
                <ModalHeaderNotification>
                    <Trans id={location.state.loginError} />
                </ModalHeaderNotification>
            )}
            <h1 className="title is-4">
                <Trans id={"user.login.title"} />
            </h1>
            <h2 className="subtitle is-6">
                <Trans id={"user.login.subtitle"} />
            </h2>
            <div ref={ref} style={{ maxWidth: 375, margin: "auto" }}>
                {parsed && (
                    <>
                        <FacebookButton
                            autoLoad={fbAutoload}
                            label={useTrans("oauth.fb.button.label")}
                            onResponse={(response) => {
                                if (response.status !== "unknown") {
                                    oauth(response, authProviders.facebook);
                                } else {
                                    setOauthLoading(false);
                                    setLoginError({ error: "user.error.login.invalid", provider: authProviders.facebook });
                                }
                            }}
                            loading={oauthLoading === authProviders.facebook || loading === authProviders.facebook}
                            disabled={inAppBrowser}
                            onClick={(e) => {
                                setLoginError({ error: null, provider: null });
                                setOauthLoading(authProviders.facebook);
                            }}
                        />
                        {loginError.error && loginError.provider === "fb" && (
                            <span className="help is-danger">
                                <Trans id={loginError.error} />
                            </span>
                        )}
                        <div style={style.button} className="has-background-black">
                            <AppleLogin
                                clientId="com.iamstudent.applelogin"
                                redirectURI={appleRedirectUrl}
                                designProp={{ width: width, height: 40, locale: locale }}
                                usePopup={true}
                                callback={(response) => {
                                    oauth(response, authProviders.apple);
                                }}
                                responseMode="form_post"
                                responseType="code id_token"
                                scope="email name"
                                autoLoad={appleAutoload}
                            />
                        </div>
                        {loginError.error && loginError.provider === authProviders.apple && (
                            <span className="help is-danger">
                                <Trans id={loginError.error} />
                            </span>
                        )}
                        <div style={style.button}>
                            <GoogleLogin
                                label={<Trans id="register.google" />}
                                className="mt-3"
                                type="standart"
                                onSuccess={(response) => {
                                    oauth(response, authProviders.google);
                                }}
                                onError={(error) => {
                                    setLoginError({ error: error.key, provider: authProviders.google });
                                    setOauthLoading(false);
                                }}
                                loading={oauthLoading === authProviders.google || loading === authProviders.google}
                                disabled={inAppBrowser}
                                onClick={() => {
                                    setLoginError({ error: null, provider: null });
                                    setOauthLoading(authProviders.google);
                                }}
                                width={width}
                            />
                        </div>

                        {loginError.error && loginError.provider === authProviders.google && (
                            <span className="help is-danger">
                                <Trans id={loginError.error} />
                            </span>
                        )}
                    </>
                )}

                {inAppBrowser && <InAppBroserNotification inAppBrowser={inAppBrowser} />}
            </div>
            <Divider content={<Trans id="default.or" />} />
            {parsed && (
                <Formik
                    initialValues={{
                        email: initEmail ?? "",
                        password: "",
                    }}
                    validationSchema={loginSchema}
                    onSubmit={(values) => {
                        login(values.email, values.password);
                    }}
                >
                    <Form noValidate>
                        <FormTextInput
                            name="email"
                            type="email"
                            label={<Trans id={"user.login.form.label.email"} />}
                            autoComplete="username"
                        />
                        <FormTextInput
                            name="password"
                            type="password"
                            label={<Trans id={"user.login.form.label.password"} />}
                            autoComplete="current-password"
                            customError={loginError.error && loginError.provider === authProviders.default ? loginError.error : null}
                        />
                        <PrimaryButton type="submit" loading={loading === authProviders.default}>
                            <Trans id={"user.login.form.label.submit"} />
                        </PrimaryButton>
                    </Form>
                </Formik>
            )}

            <p className="is-size-7 mt-4">
                <Trans id={"not_registered"} />
                &nbsp;
                {registerPathOrFunc && typeof registerPathOrFunc === "string" && (
                    <NavLink to={baseUrl + registerPathOrFunc}>
                        <Trans id="user.register" />
                    </NavLink>
                )}
                {registerPathOrFunc && typeof registerPathOrFunc === "function" && (
                    <Link onClick={registerPathOrFunc}>
                        <Trans id="user.register" />
                    </Link>
                )}
                <br />
                <ForgotPassword />
            </p>
        </Modal>
    );
}
function ForgotPassword(baseUrl) {
    return (
        <Trans
            id={"user.login.lostpassword.info"}
            values={{
                supportLink: (
                    <Link to={`${config("international_platform_url")}/user/lostpassword`} openNewTab>
                        <Trans id="user.lostpassword" />
                    </Link>
                ),
            }}
        />
    );
}
const style = {
    button: {
        width: "100%",
        height: "40px",
        borderRadius: "2.25px",
        cursor: "pointer",
        marginTop: "20px",
        display: "flex",
        justifyContent: "center",
    },
};
