import { animScrollTop, CountrySelector, CountrySelectorButton, LanguageSelector, Link, Trans, usePlatformUrl, UserContext } from "base";
import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { BaseUrlContext } from "../../base/components/baseprovider/BaseUrlProvider";

export function DefaultViewFooter({ availableCountries = [] }) {
    const baseUrl = useContext(BaseUrlContext);
    const [isCountrySelectorVisible, setCountrySelectorVisible] = useState(false);
    const theme = useContext(ThemeContext);
    const background = theme.color.background ?? null;
    const textColor = theme.color.text ?? "unset";
    const currentYear = new Date().getFullYear();
    return (
        <>
            <footer className="is-size-7 pb-3 box" style={{ backgroundColor: background, borderRadius: "0" }}>
                <div className="is-flex is-flex-wrap-wrap is-flex-row px-2 is-justify-content-space-between">
                    <div className="is-flex is-flex-row" style={{ overflow: "auto" }}>
                        <Link to={baseUrl + "support"} className={`p-2 is-white-space-nowrap has-text-grey-dark `}>
                            <div style={{ color: textColor }}>
                                <Trans id="support.title" />
                            </div>
                        </Link>
                        <PrivacyAndTerms style={{ color: textColor }} />
                        <Link className={`p-2 has-text-grey-dark`} to="https://www.iamstudent.at/impressum" openNewTab>
                            <div style={{ color: textColor }}>
                                <Trans id="imprint" />
                            </div>
                        </Link>
                    </div>
                    <div className="is-flex is-flex-row">
                        <UserStatus textColor={textColor} />
                        <CountrySelectorButton
                            availableCountries={availableCountries}
                            textColor={textColor}
                            onClick={() => setCountrySelectorVisible(true)}
                        />
                        <span style={{ maxWidth: 130 }}>
                            <LanguageSelector textColor={textColor} />
                        </span>
                    </div>
                </div>
                <div className="px-4">
                    <div style={{ color: textColor }}>© {currentYear}. High Five GmbH. All rights reserved.</div>
                </div>
            </footer>
            <CountrySelector visible={isCountrySelectorVisible} onClose={closeCountrySelector} availableCountries={availableCountries} />
        </>
    );

    function closeCountrySelector() {
        setCountrySelectorVisible(false);
    }
}

function UserStatus({ textColor }) {
    const user = useContext(UserContext);
    const color = textColor ?? "unset";
    if (!user) return null;

    if (user.isLoggedIn) {
        return (
            <Link
                onClick={() => {
                    user.logout();
                    animScrollTop();
                }}
                className="is-size-7 has-text-grey-dark p-2"
            >
                <div style={{ color: color }}>
                    <Trans id="user.logout" />
                </div>
            </Link>
        );
    }

    return null;
}

function PrivacyAndTerms({ style }) {
    let privacyUrl = usePlatformUrl("privacy");
    let termsUrl = usePlatformUrl("terms");

    return (
        <>
            <Link to={privacyUrl} className="p-2 has-text-grey-dark" openNewTab>
                <div style={style}>
                    <Trans id="support.privacy.terms" />
                </div>
            </Link>
            <Link to={termsUrl} className="p-2 has-text-grey-dark" openNewTab>
                <div style={style}>
                    <Trans id="support.business.terms" />
                </div>
            </Link>
        </>
    );
}
