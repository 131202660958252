import { FaBell, FaRegBell } from "react-icons/fa";
import {
    API,
    config,
    CountrySelector,
    CountrySelectorButton,
    GA,
    Icon,
    IntlContext,
    Link,
    Modal,
    NavLink,
    PrimaryButton,
    PrimaryButtonLink,
    SecondaryButton,
    Trans,
    useIsMounted,
    UserContext,
    useTrans,
    useWindowWidth,
    VOUCHER_USE_TYPE,
    WhatIsIamStudent,
} from "base";
import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import VoucherCode from "../components/VoucherCode";
import { routes } from "../routes";
import { ThemeContext } from "styled-components";
import { hexToRgb, rgbStringToHex } from "../../base";

function VoucherView({
    isLoggedIn,
    isVerified,
    hasLoaded,
    appInfo,
    userStatusError,
    voucher,
    company,
    appInfoError,
    loginPath,
    registerPath,
    isLocalStorageAvailable,
}) {
    // state
    const [redeemInfo, setRedeemInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [redeemError, setRedeemError] = useState("");
    const [voucherStatus, setVoucherStatus] = useState(null);
    const [wrongPin, setWrongPin] = useState(false);
    const theme = useContext(ThemeContext);
    const user = useContext(UserContext);
    const isMounted = useIsMounted();

    // load redeem info whenever voucher or loggedIn-status changes
    useEffect(() => {
        if (voucher && isVerified && !loading) {
            const shouldAutoSendRequest = voucher.useType !== VOUCHER_USE_TYPE.MOBILE_PIN;
            if (shouldAutoSendRequest) {
                loadRedeemInfo();
            }
            loadVoucherStatus();
        }
    }, [voucher, isLoggedIn, isVerified]);

    function loadRedeemInfo(pin) {
        const token = API.getToken();
        if (!token) return;

        const data = pin ? JSON.stringify({ access_code: pin }) : "";
        setLoading(true);

        return API.request("voucher/" + voucher.id + "/redeem", "POST", token, data)
            .then((response) => {
                if (isMounted.current) {
                    setRedeemInfo(response);
                    setRedeemError("");
                    setLoading(false);
                }
                return Promise.resolve(response);
            })
            .catch((error) => {
                if (isMounted.current) {
                    setRedeemInfo(null);
                    console.log(error.key);
                    if (!pin) {
                        setRedeemError(error.key);
                    } else {
                        setWrongPin(true);
                    }
                    setLoading(false);
                }
                return Promise.reject(error);
            });
    }

    function loadVoucherStatus() {
        API.request(`voucher/${voucher.id}/status`, "GET", API.getToken())
            .then((response) => {
                if (isMounted.current) {
                    if (response.isFollowing) {
                        setVoucherStatus(null);
                    } else {
                        setVoucherStatus("inactive");
                    }
                }
            })
            .catch(() => {
                if (isMounted.current) {
                    setVoucherStatus("inactive");
                }
            });
    }
    const containerHeight = useWindowWidth() < 768 ? 200 : 600;
    let content;
    if (user && user.isLoggingIn) {
        content = (
            <div className="section has-small-padding-mobile" style={{ minHeight: containerHeight }}>
                <LoadingState
                    loadId="user.login.in_progress"
                    voucher={null}
                    company={company}
                    hasLoaded={false}
                    minHeight={containerHeight}
                />
            </div>
        );
    } else if (appInfoError) {
        content = <AppInfoError error={appInfoError} />;
    } else if (voucher) {
        if (loading && isLoggedIn) {
            content = (
                <LoadingState
                    loadId="voucher.redeeming"
                    voucher={voucher}
                    company={company}
                    hasLoaded={hasLoaded}
                    minHeight={containerHeight}
                />
            );
        } else {
            content = (
                <LoadedState
                    voucher={voucher}
                    redeemInfo={redeemInfo}
                    loginPath={loginPath}
                    registerPath={registerPath}
                    isLoggedIn={isLoggedIn}
                    isVerified={isVerified}
                    isLocalStorageAvailable={isLocalStorageAvailable}
                    loadRedeemInfo={loadRedeemInfo}
                    voucherStatus={voucherStatus}
                    setVoucherStatus={setVoucherStatus}
                    wrongPin={wrongPin}
                    theme={theme}
                />
            );
        }
    } else {
        content = (
            <LoadingState
                loadId="voucher.loading"
                voucher={voucher}
                appInfo={appInfo}
                company={company}
                hasLoaded={hasLoaded}
                minHeight={containerHeight}
            />
        );
    }
    // render
    return (
        <div className="section has-small-padding-mobile pb-0" style={{ minHeight: containerHeight }}>
            {content}

            {userStatusError && !isVerified && (
                <div className="notification is-danger is-light has-text-weight-medium is-medium mb-4">
                    <Trans id="voucher.notverified" />
                </div>
            )}

            {isVerified && redeemError && (
                <div className="notification is-danger is-light has-text-weight-medium is-medium mb-4">
                    <Trans id={redeemError} />
                </div>
            )}
        </div>
    );
}

function AppInfoError({ error }) {
    const [isOpen, setOpen] = useState(false);
    if (!error) {
        return null;
    }

    const countries = error.response.availableCountries;

    return (
        <>
            <div className="notification">
                <Trans id={error.key} />
                {error.key === "error.connect.notavailableinthiscountry" && countries && countries.length > 0 && (
                    <div className="p-4">
                        <CountrySelectorButton availableCountries={countries} onClick={() => setOpen(true)} forceShow={true} />
                    </div>
                )}
            </div>
            <CountrySelector visible={isOpen} onClose={() => setOpen(false)} availableCountries={countries} position={"top"} />
        </>
    );
}

function LoadingState({ loadId, voucher, appInfo, company, hasLoaded, bgClass = "is-light", minHeight = "auto" }) {
    if (hasLoaded && voucher === null) {
        const countries = appInfo && appInfo.availableCountries;
        if (countries && countries.length > 0) {
            return (
                <AppInfoError
                    error={{
                        key: "error.connect.notavailableinthiscountry",
                        response: {
                            availableCountries: countries,
                        },
                    }}
                />
            );
        } else {
            return (
                <div className="container is-measure is-full-width">
                    <div
                        className={
                            "notification is-flex is-justify-content-center is-align-items-center is-flex-direction-column " + bgClass
                        }
                        style={{ minHeight: minHeight }}
                    >
                        <SelfAd company={company} />
                    </div>
                </div>
            );
        }
    } else {
        return (
            <div className="container is-measure is-full-width">
                <div
                    className={"notification is-flex is-justify-content-center is-align-items-center " + bgClass}
                    style={{ minHeight: minHeight }}
                >
                    <div className="load is-loading-inline is-padding-small mr-1"></div>
                    <Trans id={loadId} />
                </div>
            </div>
        );
    }
}

function LoadedState({
    voucher,
    redeemInfo,
    loginPath,
    registerPath,
    isLoggedIn,
    isVerified,
    isLocalStorageAvailable,
    loadRedeemInfo,
    voucherStatus,
    setVoucherStatus,
    theme,
    wrongPin,
}) {
    const { descriptionCompact, descriptionLong, descriptionShort, company, terms } = voucher;
    const requirementText = useTrans("voucher.requirement", {
        company: company.nickname,
    });
    const backgroundRgb = hexToRgb(rgbStringToHex(theme.color.boxBackground));
    return (
        <>
            <h1 className="title is-4">{descriptionCompact}</h1>
            <Subtitle
                descriptionLong={descriptionLong}
                descriptionShort={descriptionShort}
                requirementText={requirementText}
                isLoggedIn={isLoggedIn}
            />
            <div className="container is-measure is-full-width">
                <>
                    {isLoggedIn ? (
                        <LoggedInState
                            redeemInfo={redeemInfo}
                            isVerified={isVerified}
                            voucher={voucher}
                            loadRedeemInfo={loadRedeemInfo}
                            wrongPin={wrongPin}
                        />
                    ) : (
                        <LoggedOutState
                            loginPath={loginPath}
                            registerPath={registerPath}
                            isLocalStorageAvailable={isLocalStorageAvailable}
                        />
                    )}
                    {(!isLoggedIn || isVerified) && <Terms terms={terms} textColor={theme.color.text} />}
                </>
            </div>
            {isLoggedIn && isVerified && (
                <FollowCompany
                    companyName={company.nickname}
                    companyId={company.id}
                    state={voucherStatus}
                    setState={setVoucherStatus}
                    theme={theme}
                />
            )}
            {!isLoggedIn && (
                <div
                    className="box mt-5 mb-2 is-measure has-margin-auto"
                    style={{
                        backgroundColor: `rgba(${backgroundRgb}, 0.05)`,
                    }}
                >
                    <WhatIsIamStudent theme={theme} />
                </div>
            )}
        </>
    );
}

function Terms({ terms, textColor }) {
    const [visible, setVisible] = useState(false);
    if (terms) {
        return (
            <>
                <Link className="is-block mt-3 mb-5 is-size-7 has-text-grey-dark" onClick={() => setVisible(true)}>
                    <div style={{ color: textColor ?? "unset" }}>
                        <Trans id="voucher.condition" />
                    </div>
                </Link>
                <Modal isVisible={visible} onClose={() => setVisible(false)} maxWidth={400}>
                    <h4 className="title is-6">
                        <Trans id="voucher.condition" />
                    </h4>
                    <p className="content" dangerouslySetInnerHTML={{ __html: terms }}></p>
                </Modal>
            </>
        );
    }
    return null;
}

function SelfAd({ company }) {
    const Intl = useContext(IntlContext);
    const country = Intl.getCountry();

    if (!company) return null;

    let platformUrl = config("platform_url_international");
    if (country === "at" || country === "de" || country === "ch") {
        platformUrl = `https://www.${config("platform")}.${country}`;
    }
    platformUrl += "?utm_source=verify&utm_medium=no%20partner%20voucher%20active";

    return (
        <>
            <Trans id="voucher.not_available" values={{ company: company.nickname }} containsHTML />

            <Link to={platformUrl} target="_blank" rel="noreferrer" className="mt-3 button is-primary has-text-white">
                <Trans id="voucher.not_available.cta" />
            </Link>
        </>
    );
}

function Subtitle({ requirementText, descriptionShort, descriptionLong, isLoggedIn }) {
    if (isLoggedIn) {
        return <div className="is-measure has-margin-auto mb-5" dangerouslySetInnerHTML={{ __html: descriptionShort }}></div>;
    } else {
        if (descriptionLong) {
            return <div className="is-measure has-margin-auto mb-5" dangerouslySetInnerHTML={{ __html: descriptionLong }}></div>;
        } else {
            return <div className="is-measure has-margin-auto mb-5">{requirementText}</div>;
        }
    }
}

function FollowCompany({ companyName, companyId, state, setState, theme }) {
    if (!state) {
        return null;
    }
    return (
        <div
            className="container box is-measure notification "
            style={{ backgroundColor: `rgba(${theme.color.boxBackground}, 0.05)`, borderRadius: "6px" }}
        >
            <p>
                <Trans
                    id="voucher.alarm.description"
                    values={{
                        company: companyName,
                        platform: config("platform"),
                    }}
                    containsHTML={true}
                />
            </p>
            <SecondaryButton className="mt-5" style={{ paddingRight: "40px" }} onClick={follow} disabled={state === "active"}>
                {state === "active" ? <Trans id="voucher.alarm.action_active" /> : <Trans id="voucher.alarm.action" />}
                <AnimatePresence>
                    <motion.span
                        className="icon is-absolute is-right mr-3"
                        animate={{
                            scale: state === "active" ? [1, 1.5, 1, 1.5, 1] : 1.0,
                            transition: {
                                type: "spring",
                                duration: 1.25,
                            },
                        }}
                    >
                        {state === "active" && <Icon icon={FaRegBell} />}
                        {state !== "active" && <Icon icon={FaBell} />}
                    </motion.span>
                </AnimatePresence>
            </SecondaryButton>
        </div>
    );

    function follow() {
        API.request(`company/${companyId}/follow`, "POST", API.getToken()).then((response) => {
            setState("active");
        });
    }
}

function LoggedInState({ redeemInfo, isVerified, voucher, loadRedeemInfo, wrongPin }) {
    return (
        <>
            {isVerified && <VoucherCode info={redeemInfo} useType={voucher.useType} loadRedeemInfo={loadRedeemInfo} wrongPin={wrongPin} />}

            {/* isVerified could be undefined (not loaded), that is why we are explicit */}
            {isVerified === false && (
                <div className="notification is-danger is-light has-text-weight-medium is-medium mb-4">
                    <Trans id="voucher.notverified" />
                </div>
            )}

            {redeemInfo && redeemInfo.url && (
                <Link
                    to={redeemInfo.url}
                    target="_blank"
                    rel="noreferrer"
                    className="mt-3 button is-primary has-text-white is-100 py-2 is-uppercase to_shop"
                    onClick={() => {
                        console.log("test"),
                            GA.logEvent("verify_voucher_gotoshop", {
                                url: redeemInfo.url,
                            });
                    }}
                >
                    <Trans id={"voucher.go_to_shop"} />
                </Link>
            )}
        </>
    );
}

function LoggedOutState({ loginPath, registerPath, isLocalStorageAvailable }) {
    if (!isLocalStorageAvailable) {
        return null;
    }
    return (
        <>
            <NavLink to={routes.userLogin}>
                <PrimaryButton>
                    <Trans id={"user.login_with_iamstudent"} />
                </PrimaryButton>
            </NavLink>
            <p className="mt-3 is-size-6">
                <Trans id={"not_registered"} />
                &nbsp;
                <NavLink to={routes.userRegister}>
                    <Trans id={"register"} />
                </NavLink>
            </p>
        </>
    );
}

export default VoucherView;
