import { DefaultFooter, DefaultHeader, useTrans } from "base";
import React from "react";
import { HeroTitle } from "../../base/components/HeroTitle";

function NotFound404({ baseUrl }) {
    return (
        <div>
            <HeroTitle baseUrl={baseUrl || ""} title={useTrans("notfound.title")} subtitle={useTrans("notfound.subtitle")} />
            <div className="container">
                <div className="section has-text-centered">
                    <h1 className="title">404</h1>
                    <h2 className="subtitle">Seite nicht gefunden.</h2>
                </div>
            </div>
        </div>
    );
}

export default NotFound404;
