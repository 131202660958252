import React from "react";
import styled from "styled-components";
import { isHexColorDark } from "../core/helper";
import { Link } from "react-router-dom";

const BasicButtonNonStyled = styled.button.attrs((props) => ({
    className: "button is-tracked-narrow",
}))``;

const BaseButtonStyled = styled.button.attrs((props) => ({
    className: "button is-tracked-narrow",
}))`
    transition: all 0.25s ease-in-out;
    background-color: #e5e5e5;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75em;
    height: 4em;
    padding: 1em 2em;
    border-radius: 0.15rem;
    width: 100%;

    &:hover {
        background-color: #d6d6d6;
        text-decoration: none;
    }
`;
const BaseLinkButtonStyled = styled(Link).attrs((props) => ({
    className: "button is-tracked-narrow",
}))`
    transition: all 0.25s ease-in-out;
    background-color: #e5e5e5;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.75em;
    height: 4em;
    padding: 1em 2em;
    border-radius: 0.15rem;
    width: 100%;

    &:hover {
        background-color: #d6d6d6;
        text-decoration: none;
    }
`;
const DefaultButtonStyled = styled(BaseButtonStyled)`
    &&& {
        color: ${(props) => props.color ?? props.theme.color.grayDark};
    }
`;

const PrimaryButtonStyled = styled(BaseButtonStyled)`
    &&& {
        background-color: ${(props) => props.theme.color.primary};
        color: ${(props) => (isHexColorDark(props.theme.color.primary) ? "#fff" : "#111")};
        text-decoration-color: ${(props) => props.theme.color.primary};

        &:hover,
        &:active {
            background-color: ${(props) => props.theme.color.primaryActive};
            color: ${(props) => props.theme.color.white};
        }
    }
`;
const PrimaryLinkButtonStyled = styled(BaseLinkButtonStyled)`
    &&& {
        background-color: ${(props) => props.theme.color.primary};
        color: ${(props) => (isHexColorDark(props.theme.color.primary) ? "#fff" : "#111")};
        text-decoration-color: ${(props) => props.theme.color.primary};

        &:hover,
        &:active {
            background-color: ${(props) => props.theme.color.primaryActive};
            color: ${(props) => props.theme.color.white};
        }
    }
`;

const SecondaryButtonStyled = styled(BaseButtonStyled)`
    &&& {
        background-color: ${(props) => props.theme.color.secondary};
        color: ${(props) => (isHexColorDark(props.theme.color.secondary) ? "#fff" : "#111")};
        text-decoration-color: ${(props) => props.theme.color.secondary};

        &:hover,
        &:active {
            background-color: ${(props) => props.theme.color.secondaryActive};
        }
    }
`;

const SmallButtonStyled = styled(BaseButtonStyled).attrs((props) => ({
    className: "is-small",
}))`
    width: auto;
    padding: 0 0.5rem;
    height: 2rem;
    line-height: 2rem;
`;

const FacebookButtonStyled = styled(BaseButtonStyled)`
    background-color: #344f8e;
    color: #fff;
    border: 0;
    height: 40px;

    &:hover {
        color: #fff;
        background-color: #3b5aa2;
    }

    &[disabled] {
        background: #344f8e;
    }
`;

const GoogleButtonStyled = styled(BaseButtonStyled)`
    background: #efefef;
    color: #111;

    &:hover {
        background: #dcdcdc;
    }
    &[disabled] {
        background: #ccc;
    }
`;

function withButton(Button) {
    return ({ onClick, loading = false, as, children, ...props }) => {
        const onClickFunc = onClick ? (e) => onClick(e) : null;
        let classes = "";
        if (loading) classes += "is-loading";
        if (props.className) classes += " " + props.className;

        return (
            <Button {...props} className={classes} onClick={onClickFunc} as={as}>
                {children}
            </Button>
        );
    };
}
export const Button = withButton(DefaultButtonStyled);
export const PrimaryButton = withButton(PrimaryButtonStyled);
export const SecondaryButton = withButton(SecondaryButtonStyled);
export const SmallButton = withButton(SmallButtonStyled);
export const StyledFacebookButton = withButton(FacebookButtonStyled);
export const StyledGoogleButton = withButton(GoogleButtonStyled);
export const BasicButton = withButton(BasicButtonNonStyled);
export const PrimaryLinkButton = PrimaryLinkButtonStyled;
