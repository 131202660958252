import React from "react";
import { Link } from "./Link";
import { Trans } from "./Trans";

export function ChangeButton({ onClick, showConfirm = false }) {
    return (
        <strong>
            <Link className="is-size-7 px-2 py-3" onClick={onClick}>
                {!showConfirm && <Trans id="default.change" />}
                {showConfirm && <Trans id="default.confirm" />}
            </Link>
        </strong>
    );
}
