import { LoadingIndicatorCentered, OnVerifiedNotification, UserVerificationState, useTrans } from "base";
import { ChangePassword, OrganizationRequest, Registration, RegistrationThanks, RegistrationVerification } from "base/views";
import React, { Suspense } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider, useMatch } from "react-router-dom";
import DefaultView from "./DefaultView";
import IndexRoute from "./IndexRoute";
import NotFound404 from "./NotFound404";
import FacebookRedirect from "./FacebookRedirect";
import { routes } from "../routes";
import UserLogin from "./Auth/UserLogin";
import UserRegistry from "./Auth/UserRegistry";
import RegistrationActivationView from "./Auth/RegistrationActivationView";
import RegistrationView from "./RegistrationView";
import AppView from "./AppView";
import Support from "./Support";
function AppRouter({ user }) {
    const { isLoggedIn, login, register, loginFacebook, loginGoogle } = user;
    const defaultView = <DefaultView isLoggedIn={isLoggedIn} loginPath="login" registerPath="register" />;
    const verifiedMessage = useTrans("register.event.verified");
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path={"/*"} element={<IndexRoute />} />
                <Route path="/at/fb-redirect" element={<FacebookRedirect />} />
                <Route path={"/:country"} element={<NotFound404 />} />
                <Route path={"/:country/:company"} element={<AppView />}>
                    <Route
                        path={routes.password}
                        element={
                            <LazyRoute>
                                <ChangePassword />
                            </LazyRoute>
                        }
                    />
                    <Route
                        path={routes.activation}
                        element={
                            <LazyRoute>
                                <RegistrationActivationView />
                            </LazyRoute>
                        }
                    />
                    <Route
                        path={routes.verify}
                        element={
                            <LazyRoute>
                                <RegistrationVerification />
                            </LazyRoute>
                        }
                    />
                    <Route
                        path={routes.thanks}
                        element={
                            <Redirecter isLoggedIn={isLoggedIn}>
                                <RegistrationThanks />
                            </Redirecter>
                        }
                    />
                    <Route
                        path={routes.register}
                        element={
                            <Redirecter isLoggedIn={isLoggedIn}>
                                <RegistrationView site="iamverify" />
                            </Redirecter>
                        }
                    />
                    <Route
                        path={"organisation/request"}
                        element={
                            <LazyRoute>
                                <OrganizationRequest />
                            </LazyRoute>
                        }
                    />
                    <Route
                        path={routes.support}
                        element={
                            <LazyRoute>
                                <Support />
                            </LazyRoute>
                        }
                    />
                    <Route index path={""} element={<LazyRoute>{defaultView}</LazyRoute>} />
                    <Route
                        path={routes.userLogin}
                        element={
                            <Redirecter isLoggedIn={isLoggedIn}>
                                <>
                                    {defaultView}
                                    <UserLogin
                                        loginFunc={login}
                                        isLoggedIn={isLoggedIn}
                                        fbLoginFunc={loginFacebook}
                                        googleLoginFunc={loginGoogle}
                                    />
                                </>
                            </Redirecter>
                        }
                    />

                    <Route
                        path={routes.userRegister}
                        element={
                            <Redirecter isLoggedIn={isLoggedIn}>
                                {defaultView}
                                <UserRegistry registerFunc={register} fbLoginFunc={loginFacebook} googleLoginFunc={loginGoogle} />
                            </Redirecter>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <LazyRoute>
                                <NotFound404 />
                            </LazyRoute>
                        }
                    ></Route>
                </Route>
            </>
        )
    );
    return (
        <RouterProvider router={router}>
            <OnVerifiedNotification
                userStatus={user ? user.verificationStatus : UserVerificationState.NULL}
                successMessage={verifiedMessage}
            />
        </RouterProvider>
    );
}

function LazyRoute({ fallback = <LoadingIndicatorCentered />, children }) {
    return <Suspense fallback={fallback}>{children}</Suspense>;
}
function Redirecter({ isLoggedIn, children }) {
    const match = useMatch("/:country/:company/*");
    const fallback = <LoadingIndicatorCentered />;
    return <>{isLoggedIn ? <Navigate to={match.pathnameBase} /> : <Suspense fallback={fallback}>{children}</Suspense>}</>;
}

export default AppRouter;
