import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

export function Trans({ id, values, children, containsHTML = false }) {
    if (containsHTML) {
        const standard = {
            strong: (chunks) => <strong>{chunks}</strong>,
            br: (chunks) => <br />,
        };
        values = {
            ...standard,
            ...values,
        };
    }

    return (
        <FormattedMessage id={id} values={values}>
            {children}
        </FormattedMessage>
    );
}

export function useTrans(id, values) {
    const intl = useIntl();

    if (!id) return "";

    if (process.env.NODE_ENV === "development") {
        if (!intl) {
            console.error("Translation failed because you are not in an Intl context!");
            return "";
        }
    }
    return intl.formatMessage(
        {
            id: id,
            defaultMessage: id,
        },
        values
    );
}
