import { useField } from "formik";
import React from "react";
import { Trans } from "../Trans";
import { FormHelpError } from "./Input";

export function FormBirthdate({ birthdayName = "birthday", birthmonthName = "birthmonth", birthyearName = "birthyear" }) {
    const [fieldDay, metaDay] = useField(birthdayName);
    const [fieldMonth, metaMonth] = useField(birthmonthName);
    const [fieldYear, metaYear] = useField(birthyearName);

    const meta = [metaDay, metaMonth, metaYear].filter((m) => typeof m.error === "string");

    return (
        <>
            <label className="label" htmlFor={birthdayName}>
                Geburtstag
            </label>
            <div className="field">
                <div className="is-flex">
                    <div className="control mr-5">
                        <span className="pl-1 is-size-7">
                            <Trans id="date.day" />
                        </span>
                        <input
                            className="input"
                            id={birthdayName}
                            type="number"
                            inputMode="decimal"
                            placeholder="23"
                            autoComplete={"bday-day"}
                            {...fieldDay}
                        />
                    </div>
                    <div className="control mr-5">
                        <span className="pl-1 is-size-7">
                            <Trans id="date.month" />
                        </span>
                        <input
                            className="input"
                            id={birthmonthName}
                            type="number"
                            inputMode="decimal"
                            placeholder="05"
                            autoComplete={"bday-month"}
                            {...fieldMonth}
                        />
                    </div>
                    <div className="control">
                        <span className="pl-1 is-size-7">
                            <Trans id="date.year" />
                        </span>
                        <input
                            className="input"
                            id={birthyearName}
                            type="number"
                            inputMode="decimal"
                            placeholder="1999"
                            autoComplete={"bday-year"}
                            {...fieldYear}
                        />
                    </div>
                </div>
                {meta.length > 0 && <FormHelpError meta={meta[0]} />}
            </div>
        </>
    );
}
