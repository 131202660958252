import React from "react";
import { useMatch } from "react-router-dom";
import RegistrationActivation from "../../../base/views/Registration/RegistrationActivation";
import { routes } from "../../routes";

function RegistrationActivationView() {
    const match = useMatch("/:country/:organisation/*");
    const redirectPath = match.pathnameBase + "/user/password/";
    return <RegistrationActivation redirectRoute={redirectPath} />;
}

export default RegistrationActivationView;
