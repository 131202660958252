import React from "react";
import { Registration } from "base/views";
import { useMatch } from "react-router-dom";

function RegistrationView({ site }) {
    const match = useMatch("/:country/:organisation/*");
    return <Registration site={site} verify={match.params.organisation} />;
}

export default RegistrationView;
