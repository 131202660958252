import { GoCheck } from "react-icons/go";
import { useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import Autosuggest from "react-autosuggest";
import styled from "styled-components";
import { FlagIcon, Icon } from "../Icon";

export function FormAutocomplete({
    label,
    getSuggestions = (value) => [], // returns an array or a promise that resolves to an array
    getSuggestionValue = getSuggestionValueDefault,
    renderSuggestion = (suggestion, { query, isHighlighted }) => {
        return renderSuggestionDefault(
            suggestion,
            getSuggestionValue,
            query,
            isHighlighted
        );
    },
    onSelected,
    onFocus,
    onChange,
    onBlur,
    validateOnBlur = false,
    disabled = false,
    disabledText = "",
    isLoading = false,
    isSuccess = false,
    requestLimitRate = 0,
    resultLimitRate = 0,
    iconLeft,
    overrideValue, // this will override the internal value after first render. you are expected to reset this value to undefined after.
    className,
    ...props
}) {
    const [field, meta, helpers] = useField(props);
    const [suggestions, setSuggestions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [lastRequestTime, setLastRequestTime] = useState(0);
    const [requestTimerId, setRequestTimerId] = useState(-1);
    const [lastResultTime, setLastResultTime] = useState(0);
    const [resultTimerId, setResultTimerId] = useState(-1);
    const ref = useRef(null);

    // override
    useEffect(() => {
        if (overrideValue || overrideValue === "") {
            helpers.setValue(overrideValue);
        }
    }, [overrideValue]);

    // add onFocus event listener
    useEffect(() => {
        if (onFocus && ref && ref.current) {
            const input = ref.current.querySelector("input");
            input.addEventListener("focus", onFocus);
            return () => {
                input.removeEventListener("focus", onFocus);
            };
        }
    }, []);

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
        name: field.name || "",
        id: field.name || "",
        placeholder: disabled ? disabledText : props.placeholder || "",
        onChange: onChangeHandler,
        onBlur: validateOnBlur ? validateWhenBlur : () => {},
        value: overrideValue !== undefined ? overrideValue : field.value,
        disabled: disabled,
    };

    const labelStyle = {
        opacity: disabled ? 0.6 : 1.0,
    };

    return (
        <div className={`field ${className ? className : ""}`}>
            <label
                className="label"
                style={labelStyle}
                htmlFor={props.id || props.name}
            >
                {label}
            </label>
            <Control ref={ref} isLoading={isLoading} iconLeft={iconLeft}>
                <StyleWrapper>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={fetch}
                        onSuggestionsClearRequested={clear}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        onSuggestionSelected={(event, { suggestion }) => {
                            if (onSelected) onSelected(suggestion);
                        }}
                        inputProps={inputProps}
                        highlightFirstSuggestion={true}
                        renderInputComponent={renderInput}
                    />
                    {isSuccess && (
                        <Icon
                            icon={GoCheck}
                            className="is-small is-right has-text-success"
                        />
                    )}
                    {iconLeft && iconLeft}
                </StyleWrapper>
            </Control>
            {meta.touched && meta.error ? (
                <div className="help is-danger">{meta.error}</div>
            ) : null}
        </div>
    );

    function onChangeHandler(event, { newValue, method }) {
        helpers.setValue(newValue);
        if (onChange && typeof onChange === "function") {
            onChange(event, newValue);
        }
        if (method === "enter") {
            event.preventDefault();
        }
    }

    function fetch({ value }) {
        const now = Date.now();
        const delta = lastRequestTime === 0 ? 0 : now - lastRequestTime;

        // debounce requests
        if (requestLimitRate > 0 && delta < requestLimitRate) {
            const newTimerId = setTimeout(() => {
                fetch({ value });
            }, requestLimitRate);
            clearTimeout(requestTimerId);
            setRequestTimerId(newTimerId);
            setLastRequestTime(now);
            return;
        }

        const newSuggestions = getSuggestions(value);
        const isPromise = typeof newSuggestions.then == "function";
        if (isPromise) {
            setLastRequestTime(now);
            setLoading(true);

            newSuggestions.then((response) => {
                // debounce results
                const now = Date.now();
                const delta = lastResultTime === 0 ? 0 : now - lastResultTime;
                if (resultLimitRate > 0 && delta < resultLimitRate) {
                    const timerId = setTimeout(() => {
                        setResult(response);
                    }, resultLimitRate);
                    clearTimeout(resultTimerId);
                    setResultTimerId(timerId);
                    setLastResultTime(now);
                } else {
                    setResult(response);
                }
            });
        } else {
            if (!newSuggestions) {
                throw "FORM: getSuggestion() for the <Autocomplete> component did not return an array.";
            }
            setSuggestions(newSuggestions);
        }
    }

    function setResult(response) {
        setSuggestions(response);
        setLoading(false);
    }

    function clear() {
        setSuggestions([]);
    }

    function validateWhenBlur(e) {
        if (onBlur) {
            onBlur();
        }
        helpers.setTouched(true);
    }
}

function getSuggestionValueDefault(suggestion) {
    return suggestion;
}

function renderSuggestionDefault(
    suggestion,
    getSuggestionValue,
    query,
    isHighlighted
) {
    const value = getSuggestionValue(suggestion);
    //return <div>{value}</div>;
    return getHighlightedSuggestion(value, suggestion, query);
}

function renderInput(inputProps) {
    return <input {...inputProps} className="input" />;
}

export function getHighlightedSuggestion(value, suggestion, query) {
    if (!value || !query) return null;

    const input = value.toLowerCase();
    const search = query.toLowerCase();
    const index = input.indexOf(search);
    let html = value;

    if (index > 0) {
        html =
            value.slice(0, index) +
            "<strong>" +
            value.slice(index, index + search.length) +
            "</strong>" +
            value.slice(index + search.length);
    }

    return (
        <>
            {suggestion.country && (
                <FlagIcon
                    country={suggestion.country.toLowerCase()}
                    className="is-small mr-2 mt-3"
                    height="100%"
                />
            )}
            <span dangerouslySetInnerHTML={{ __html: html }}></span>
        </>
    );
}

const Control = styled.div.attrs((props) => ({
    className:
        "control has-icons-right" +
        (props.isLoading ? " is-loading" : "") +
        (props.iconLeft ? " has-icons-left" : ""),
}))``;

const StyleWrapper = styled.div`
    & .react-autosuggest__container {
        position: relative;
    }

    & .react-autosuggest__container--open {
    }

    & .react-autosuggest__input {
    }

    & .react-autosuggest__input--open {
    }

    & .react-autosuggest__input--focused {
        outline: none;
    }

    & .react-autosuggest__suggestions-container {
        background: #fff;
        width: 100%;
        position: absolute;
        z-index: 5;
        overflow-y: scroll;
    }

    & .react-autosuggest__suggestions-container--open {
        border: 1px solid #ddd;
        max-height: 44vh;
    }

    & .react-autosuggest__suggestions-list {
    }

    & .react-autosuggest__suggestion {
        line-height: 1.6em;
        font-size: 0.9em;
        padding: 0 8px 6px;
    }

    & .react-autosuggest__suggestion--highlighted {
        background: ${(props) => props.theme.color.primary};
        color: white;
    }

    & .react-autosuggest__suggestion--first {
    }

    .sectionContainer {
    }

    .sectionContainerFirst {
    }

    .sectionTitle {
    }
`;
