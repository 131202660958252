import { GoCheck } from "react-icons/go";
import {
    API,
    DefaultFooter,
    FormSelect,
    FormTextarea,
    FormTextInput,
    Icon,
    IntlContext,
    Link,
    NavLink,
    PrimaryButton,
    ScrollTop,
    Trans,
    usePlatformUrl,
    useTrans,
} from "base";
import { Form, Formik } from "formik";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation, useMatch, useOutletContext, useRouteMatch } from "react-router-dom";
import * as Yup from "yup";
import { DefaultHeader } from "../../base";
import { StandaloneHeader } from "./StandaloneView";

function Support(props) {
    const match = useMatch("/:lang/:company/*");
    const backUrl = match.pathnameBase;
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    const [title, setTitle] = useState(null);
    const [url, setUrl] = useState(null);
    const location = useLocation();
    const [appInfo, setAppInfo] = useOutletContext();
    const [company, setCompany] = useState("");
    const Intl = React.useContext(IntlContext);
    const activeLang = Intl.getLanguage();
    let defaultSubject;
    if (location.search) {
        const parsed = queryString.parse(location.search);
        if (parsed.subject) {
            defaultSubject = parsed.subject;
        }
    }

    function submit(values, bag) {
        setSuccess(false);
        setError("");

        values.subject = "verify: " + values.subject + " (" + match.url + ")";

        API.request("support/create", "POST", "", JSON.stringify(values))
            .then((response) => {
                bag.setSubmitting(false);
                setSuccess(true);
            })
            .catch((error) => {
                bag.setSubmitting(false);
                setError(error.message);
            });
    }
    useEffect(() => {
        if (appInfo) {
            setCompany(appInfo.company);
            setTitle(appInfo.company.nickname);
            setUrl(appInfo.company.website);
        }
    }, [appInfo]);
    return (
        <>
            <ScrollTop />
            <StandaloneHeader company={company} title={title} url={backUrl} className="mx-3" />
            <div className="section container is-measure-wide">
                {activeLang !== "de" && activeLang !== "en" && (
                    <div className="notification is-info">Please note that we can currently only offer support in English.</div>
                )}
                {success ? <Success backUrl={backUrl} /> : <SupportForm submit={submit} error={error} defaultSubject={defaultSubject} />}

                <div className="notification mt-3">
                    <p className="is-size-7">
                        <Trans
                            id={"support.privacy.info"}
                            values={{
                                privacyPolicyLink: (
                                    <Link to={usePlatformUrl("privacy")} openNewTab>
                                        <Trans id="support.privacy.terms" />
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
            </div>
        </>
    );
}

function SupportForm({ submit, error, defaultSubject }) {
    const token = API.getToken();
    let defaultEmail = "";
    if (token) {
        const struct = API.parseJwt(token);
        defaultEmail = struct && struct.email;
    }

    const schema = Yup.object().shape({
        subject: Yup.string().required(useTrans("support.form.error.subject")),
        name: Yup.string().required(useTrans("support.form.error.name")),
        email: Yup.string().email(useTrans("error.email.invalid")).required(useTrans("error.email.required")),
        message: Yup.string().min(15, useTrans("support.form.error.message_more")).required(useTrans("support.form.error.message")),
    });

    const subjects = [
        {
            value: "registrierung",
            label: useTrans("support.form.label.subject.registration"),
        },
        {
            value: "account",
            label: useTrans("support.form.label.subject.account"),
        },
        {
            value: "voucher",
            label: useTrans("support.form.label.subject.voucher"),
        },
        {
            value: "verification",
            label: useTrans("support.form.label.subject.verify"),
        },
        {
            value: "feedback",
            label: useTrans("support.form.label.subject.feedback"),
        },
        {
            value: "other",
            label: useTrans("support.form.label.subject.other"),
        },
    ];

    const initialValues = {
        subject: defaultSubject || subjects[0].value,
        name: "",
        email: defaultEmail,
        message: "",
    };

    return (
        <>
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={submit}>
                {({ isSubmitting }) => (
                    <Form noValidate>
                        <FormSelect name="subject" label={<Trans id="support.form.label.subject" />}>
                            {subjects.map((t, index) => {
                                return (
                                    <option value={t.value} key={t.value + index}>
                                        {t.label}
                                    </option>
                                );
                            })}
                        </FormSelect>
                        <FormTextInput name="name" label={<Trans id="support.form.label.name" />} />
                        <FormTextInput name="email" type="email" label={<Trans id="support.form.label.email" />} />
                        <FormTextarea name="message" label={<Trans id="support.form.label.message" />} rows="4" />

                        <PrimaryButton type="submit" loading={isSubmitting} disabled={isSubmitting}>
                            <Trans id={"support.form.label.submit"} />
                        </PrimaryButton>
                    </Form>
                )}
            </Formik>
            {error && <div className="help is-danger mt-3">{error}</div>}
        </>
    );
}

function Success({ backUrl }) {
    return (
        <div className="notification is-success is-light">
            <Icon icon={GoCheck} />
            <Trans id={"support.msg.success"} />
            &nbsp;
            <NavLink to={backUrl}>
                <Trans id={"default.back"} />
            </NavLink>
        </div>
    );
}

export default Support;
