import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { HeroTitle } from "../../components/HeroTitle";
import { Trans, useTrans } from "../../components/Trans";
import { API } from "../../core/api";
import { GA } from "../../core/google_analytics";
import { metaTitle } from "../../core";
import { Link } from "../../components";
import { routes } from "../../../src/routes";
import { BaseUrlContext } from "../../components/baseprovider/BaseUrlProvider";

function RegistrationActivation({ redirectRoute = "/user/password/" }) {
    const baseUrl = useContext(BaseUrlContext);
    const history = useNavigate();
    const { activationKey } = useParams();
    const [error, setError] = useState("");
    const [hasRendered, setHasRendered] = React.useState(false);

    console.log(baseUrl);
    metaTitle(useTrans("registration.finish"));

    useEffect(() => {
        if (!hasRendered) {
            return;
        } else {
            API.request(
                "register/activate",
                "POST",
                "",
                JSON.stringify({
                    code: activationKey,
                })
            )
                .then((response) => {
                    redirectUser(response);
                })
                .catch((error) => {
                    if (API.getToken()) {
                        redirectIfUserHasToken();
                    } else {
                        setError(error.key);
                    }
                });
        }
    }, [hasRendered]);
    useEffect(() => {
        setTimeout(() => {
            setHasRendered(true);
        }, 1000);
    }, []);

    function redirectUser(response) {
        if (response && response.token) {
            GA.logEvent("user_activated");
            history(redirectRoute + activationKey, {
                state: {
                    token: response.token,
                    refresh_token: response.refresh_token,
                },
            });
        } else {
            redirectIfUserHasToken();
        }
    }

    function redirectIfUserHasToken() {
        let token = API.getToken();
        if (token) {
            if (baseUrl.indexOf("http") >= 0) {
                window.location.href = baseUrl;
            } else {
                history({
                    pathname: baseUrl,
                    state: {
                        shouldLogin: true,
                    },
                });
            }
        }
    }

    return (
        <>
            <HeroTitle title={<Trans id="registration.finish" />} subtitle={<Trans id="registration.activation" />} />
            <div className="container section is-measure-wide has-text-centered">
                {!error && (
                    <>
                        <div className="is-size-1 loada is-loading-inline">
                            <span className="is-sr-only">Activating user ...</span>
                        </div>
                        <p className="mt-5">
                            <Trans id="registration.activating" containsHTML />
                        </p>
                    </>
                )}

                {error && (
                    <>
                        <div className="notification is-warning is-light">
                            <h2 className="is-6 has-text-weight-bold">
                                <Trans id={"register.activate.error"} />:
                            </h2>
                            <p className="is-6">
                                <Trans id={error} />
                            </p>
                            <p className="is-6 mt-3">
                                <Trans
                                    id="error.activation.link"
                                    containsHTML
                                    values={{
                                        loginLink: (
                                            <Link to={routes.userLogin}>
                                                <Trans id="user.login" />
                                            </Link>
                                        ),
                                    }}
                                />
                            </p>
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default RegistrationActivation;
